import { Injectable } from '@angular/core';
import { addDoc, collection, doc, Firestore, setDoc } from '@angular/fire/firestore';

@Injectable({
	providedIn: 'root',
})
export class PIE_OrderS_Service {
	constructor(private afs: Firestore) {}
	async setOrder(id: string, payload: any) {
		const ref = doc(this.afs, 'pie-orders', id);
		setDoc(ref, payload, { merge: true });
		return id;
	}

	async addOrder(payload: any) {
		const ref = collection(this.afs, 'pie-orders');
		const id = await addDoc(ref, payload).then(result => result);
		return id;
	}
}
