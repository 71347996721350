import { Injectable } from '@angular/core';
import { addDoc, collection, CollectionReference, collectionSnapshots, deleteDoc, doc, DocumentData, Firestore, getDocs, query, setDoc, where } from '@angular/fire/firestore';
import { ChatSubject } from '../models/chat-subjects.model';
import { map } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class SubjectsService {
	public pendingSubscription: string | null = null;

	constructor(private afs: Firestore) {}

	async getSubjectsAsPromise() {
		const q = query<ChatSubject, DocumentData>(collection(this.afs, 'chat-subjects') as CollectionReference<ChatSubject>);
		return (await getDocs(q)).docs.map(m => {
			const data = m.data();
			data.id = m.id;
			return <ChatSubject>data;
		});
	}

	getSubjectsAsObservable() {
		return collectionSnapshots<ChatSubject>(
			query<ChatSubject, DocumentData>(collection(this.afs, 'chat-subjects') as CollectionReference<ChatSubject>, where('subjectStatus', '!=', 'Closed'))
		).pipe(
			map(changes => {
				return changes.map(m => {
					const data = m.data();
					data.id = m.id;
					return <ChatSubject>data;
				});
			})
		);
	}

	async addSubject(payload: ChatSubject) {
		const ref = collection(this.afs, 'chat-subjects');
		await addDoc(ref, payload);
	}

	deleteSubject(id: string) {
		const ref = doc(this.afs, 'chat-subjects', id);
		deleteDoc(ref);
	}

	setSubject(id: string, payload: ChatSubject) {
		const ref = doc(this.afs, 'chat-subjects', id);
		setDoc(ref, payload, { merge: true });
	}
}
