export interface Meeting {
	id?: string;
	header?: {
		endTime?: Date;
		startTime?: Date;
	};
	attendance?: [
		{
			accountId: string;
			isPresent?: 'present' | 'not present' | null;
			name: string;
		}
	];
}

export interface MeetingAttendance {
	accountId: string;
	isPresent?: string | null;
	name: string;
}
