import { ErrorHandler, Injectable } from '@angular/core';
import * as Sentry from '@sentry/angular';

@Injectable({
	providedIn: 'root',
})
export class GlobalErrorHandler implements ErrorHandler {
	constructor() {}

	handleError(error: any): void {
		// Report the error to Sentry
		Sentry.captureException(error.originalError || error);

		// Optionally log the error to the console as well
		console.error('Error captured by GlobalErrorHandler:', error);
	}
}
