import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { doc, docData, Firestore, setDoc } from '@angular/fire/firestore';
import { catchError, lastValueFrom, map, Observable } from 'rxjs';
import { firebase, quickbooks } from 'src/environments';
import { QBToken } from '../models';
import { captureError, logToConsole } from '../utilities';

@Injectable({
	providedIn: 'root',
})
export class QuickbooksService {
	private isLocalhost = window.location.hostname === 'localhost';
	private functionsAPIEndpoint = this.isLocalhost ? firebase.Cfg.emulatorEndpoint : firebase.Cfg.liveEndpoint;

	constructor(private http: HttpClient, private afs: Firestore) {}

	/***********************************************************************************************************************
	 * Methods using the Quickbooks API
	 ***********************************************************************************************************************/
	async getCustomers(caller: string): Promise<any> {
		logToConsole(`'QuickbooksService=>getCustomers=>caller:'`, caller);

		try {
			const headers = new HttpHeaders({
				endpoint: quickbooks.end_point,
				company: quickbooks.company,
			});
			const result = this.http.get(`${this.functionsAPIEndpoint}/qb/customers`, { headers });
			return await lastValueFrom(result);
		} catch (error) {
			captureError('Error in getCustomers:', error);
			throw error;
		}
	}

	async getUpdates(caller: string): Promise<any> {
		logToConsole(`'QuickbooksService=>getUpdates=>caller:'`, caller);

		try {
			const headers = new HttpHeaders({
				endpoint: quickbooks.end_point,
				company: quickbooks.company,
			});
			const result = this.http.get(`${this.functionsAPIEndpoint}/qb/get_updates`, { headers });
			return await lastValueFrom(result);
		} catch (error) {
			captureError('Error in getUpdates:', error);
			throw error;
		}
	}

	async getCustomerByEmail(caller: string, email: string): Promise<any> {
		logToConsole(`'QuickbooksService=>getCustomerByEmail=>caller:'`, caller);

		try {
			const headers = new HttpHeaders({
				endpoint: quickbooks.end_point,
				company: quickbooks.company,
				email: email,
			});
			const result = this.http.get(`${this.functionsAPIEndpoint}/qb/getCustomerByEmail`, { headers });
			return await lastValueFrom(result);
		} catch (error) {
			captureError('Error in getCustomerByEmail:', error);
			throw error;
		}
	}

	/* async setToken(token: QBToken): Promise<void> {
		const ref = doc(this.afs, 'mas-parameters', 'quickbooksAPI');

		// Clean out undefined values
		const cleanToken: Record<string, any> = {};
		for (const [key, value] of Object.entries(token)) {
			if (value !== undefined) cleanToken[key] = value;
		}

		try {
			// ✅ Pass cleanToken directly (no object wrapping)
			await updateDoc(ref, cleanToken);
		} catch (err: any) {
			if (err.code === 'not-found' || err.message?.includes('No document')) {
				// No need to safeStringify here — just reuse cleanToken
				await setDoc(ref, cleanToken, { merge: true });
			} else {
				throw err;
			}
		}
	}
 */
	async generateAndSaveShortLivedToken(caller: string): Promise<void> {
		logToConsole('QuickbooksService=>generateAndSaveShortLivedToken=>caller:', caller);

		try {
			const now = Date.now();
			const accessTokenExpiresIn = 20 * 1000; // 20 seconds
			const refreshTokenExpiresIn = 60 * 1000; // 60 seconds

			// 🔥 Partial payload to overwrite expiration times only
			const patch = {
				expires_in: accessTokenExpiresIn / 1000,
				server_time: now,
				expires_time: now + accessTokenExpiresIn,
				refresh_time: now + refreshTokenExpiresIn,
				refresh_expires_time: now + refreshTokenExpiresIn,
				x_refresh_token_expires_in: refreshTokenExpiresIn / 1000,
			};

			// 🔄 Merge with existing token
			const ref = doc(this.afs, 'mas-parameters', 'quickbooksAPI');
			await setDoc(ref, patch, { merge: true });

			logToConsole(`🔁 Short-lived token fields patched in Firestore by ${caller}`);
		} catch (error) {
			captureError('Error in generateAndSaveShortLivedToken:', error);
			throw error;
		}
	}

	async setLastCustomerUpdate(caller: string): Promise<void> {
		try {
			const lastCustomerUpdate = Date.now();

			// Update Firestore with the new lastCustomerUpdate value only
			const ref = doc(this.afs, 'mas-parameters', 'quickbooksAPI');
			await setDoc(ref, { lastCustomerUpdate }, { merge: true });

			logToConsole('Last Customer Update written to Firestore:', lastCustomerUpdate);
		} catch (error) {
			captureError('QuickbooksService=>setLastCustomerUpdate', error);
			throw error;
		}
	}
	getLastTokenAsObservable(): Observable<QBToken> {
		const ref = doc(this.afs, 'mas-parameters/quickbooksAPI');
		return docData(ref).pipe(
			map(data => data as QBToken),
			catchError(error => {
				captureError('Error fetching Quickbooks API data', error);
				throw error;
			})
		);
	}
}
