export const firebase = {
	production: false,
	Cfg: {
		apiKey: 'AIzaSyCnnKIycurp2XrofiKuOOhx17g0U2vAOSk',
		authDomain: 'mas-development-53ac7.firebaseapp.com',
		databaseURL: 'https://mas-development-53ac7.firebaseio.com',
		projectId: 'mas-development-53ac7',
		storageBucket: 'mas-development-53ac7.appspot.com',
		messagingSenderId: '472191207902',
		appId: '1:472191207902:web:fa19a266bfccba0baa354f',
		measurementId: 'G-99NDJ28KET',
		liveEndpoint: 'https://us-central1-mas-development-53ac7.cloudfunctions.net',
		emulatorEndpoint: 'http://localhost:5001/mas-development-53ac7/us-central1',
	},
	googleDomain: 'yongsa.net',
	offSetDays: 1,
	PayPal_Client_Id: 'AT7N55QgBstZFh1VPSXCsGLt2NBi94EOEpIthf3eVhInpePmMw2o7-08iZv_yOeUzpS5jjcmtPFvWAGY',
};
