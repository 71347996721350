import { Injectable, Injector } from '@angular/core';
import { MASProgramModel, Program } from '../models';
import { MAS_Programs_Service } from '../services';

@Injectable({ providedIn: 'root' })
export class MasProgramConverterService {
	private _programsService!: MAS_Programs_Service;

	constructor(private injector: Injector) {}

	private get programsService(): MAS_Programs_Service {
		if (!this._programsService) {
			this._programsService = this.injector.get(MAS_Programs_Service);
		}
		return this._programsService;
	}

	fromRaw(raw: Program): MASProgramModel {
		return new MASProgramModel(raw, this.programsService);
	}

	toRaw(model: MASProgramModel): Program {
		return model.toJSON();
	}
}
