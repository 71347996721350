import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { GlobalErrorHandler } from './error-handler.service';
import { CustomError } from '../models/global.model';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
	constructor(private errorHandler: GlobalErrorHandler) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(request).pipe(
			catchError((error: HttpErrorResponse) => {
				// Convert HttpErrorResponse to CustomError
				const customError = new CustomError(error.message, {
					status: error.status,
					statusText: error.statusText,
					url: error.url,
					headers: error.headers,
					error: error.error,
				});

				// Use the global error handler to handle HTTP errors globally
				this.errorHandler.handleError(customError);

				return throwError(() => customError);
			})
		);
	}
}
