import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { TokenRefreshError } from '../utilities';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(request).pipe(
			catchError((error: HttpErrorResponse) => {
				// Check if the request is for token refresh and if the error status is 400.
				if (request.url.includes('/qb/refresh_token') && error.status === 400) {
					// Create a new custom error instance with extra context.
					const tokenError = new TokenRefreshError('Token refresh failed', error.status);
					// Optionally, perform additional logging here.
					return throwError(() => tokenError);
				}
				// For any other errors, rethrow the original error.
				return throwError(() => error);
			})
		);
	}
}
