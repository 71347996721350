import { Injectable } from '@angular/core';
import { addDoc, collection, CollectionReference, doc, DocumentData, Firestore, getDoc, getDocs, query, setDoc, where } from '@angular/fire/firestore';
import { CustomFunctions } from '../models';
import { Question, QuestionsWithChoices } from '../models/mas-trivia.model';

@Injectable({
	providedIn: 'root',
})
export class MAS_Trivia_Service {
	/* 	public serviceQuestionData: TrivaQuestionData = {
		mas: { triviaQuestionData: { cumulativeCorrect: 0, lastCorrectDate: '', lastDate: '' } },
	};
 */
	constructor(private afs: Firestore) {}

	getTriviaQuestionsTigers() {
		const q = query<Question, DocumentData>(collection(this.afs, 'mas-trivia') as CollectionReference<Question>, where('type', '==', 'Tigers'));
		return getDocs(q).then(data => data.docs.map(doc => ({ id: doc.id, ...doc.data() })));
	}

	getTriviaQuestionsStandard() {
		const q = query<Question, DocumentData>(collection(this.afs, 'mas-trivia') as CollectionReference<Question>, where('type', '!=', 'Tigers'));
		return getDocs(q).then(data => data.docs.map(doc => ({ id: doc.id, ...doc.data() })));
	}

	setTrivia(payload: {}, id: string) {
		const docRef = doc(this.afs, 'mas-trivia', id);
		setDoc(docRef, payload, { merge: true });
	}

	newTriviaQuestion(payload: {}) {
		const ref = collection(this.afs, 'mas-trivia');
		addDoc(ref, payload);
	}

	getRandomQuestion(data: Question[]): QuestionsWithChoices {
		if (data.length === 0) throw new Error('No data available');

		// Get a random trivia question
		const randomIndex = Math.floor(Math.random() * data.length);
		const question = data[randomIndex];

		// Initialize answer array with the correct answer
		const answerArray = new Set([question.answer]);

		// Filter wrong answers and shuffle them
		let wrongAnswers = data.filter(q => q.type === question.type && q.answer !== question.answer).map(q => q.answer);
		wrongAnswers = CustomFunctions.shuffle(wrongAnswers);

		// Add up to 3 unique wrong answers
		const maxWrongAnswers = question.type !== 'Boolean' ? 3 : 1;
		for (let i = 0; i < Math.min(maxWrongAnswers, wrongAnswers.length); i++) {
			answerArray.add(wrongAnswers[i]);
		}

		if (answerArray.size <= 1) {
			throw new Error('Insufficient unique answers available');
		}

		// Create a new object to avoid modifying the input
		const questionWithChoices: QuestionsWithChoices = {
			...question,
			answers: CustomFunctions.shuffle(Array.from(answerArray)),
		};

		return questionWithChoices;
	}

	async getTrivia(programNames: string[]) {
		let id = 'standard';

		if (typeof programNames !== 'undefined' && programNames.includes('Tigers')) id = 'tigers';

		const docRef = doc(this.afs, 'mas-trivia', id);
		const docSnap = await getDoc(docRef);

		return docSnap.data();
	}

	async getTriviaQuestions(mode: 'standard' | 'tigers') {
		const docRef = doc(this.afs, 'mas-trivia', mode);
		const docSnap = await getDoc(docRef);

		return docSnap.data();
	}
}
