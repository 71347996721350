import { Injectable } from '@angular/core';
import { CollectionReference, Firestore, collection, doc, getDocs, query, setDoc, where, addDoc } from '@angular/fire/firestore';
import { ChatAccount } from '../models/chat-accounts.model';
import { ChatSubject } from '../models/chat-subjects.model';

@Injectable({
	providedIn: 'root',
})
export class ChatAccountsService {
	constructor(private afs: Firestore) {}

	setAccount(account: ChatAccount, id: string) {
		const ref = doc(this.afs, 'chat-accounts', id);
		setDoc(ref, account, { merge: true });
	}

	async getRoles() {
		const q = query(collection(this.afs, 'chat-roles') as CollectionReference);
		return (await getDocs(q)).docs.map(m => {
			const data = m.data();
			data['id'] = m.id;
			return data;
		});
	}

	async getAccount(phone: string) {
		const q = query(collection(this.afs, 'chat-accounts') as CollectionReference, where('data.phoneNumber', '==', phone));

		const querySnapshot = await getDocs(q);

		const data = querySnapshot.docs
			.map(m => {
				const docData = m.data();
				docData['id'] = m.id;
				return docData;
			})
			.pop() as ChatAccount;

		return data;
	}

	async addAdmin(adminRoles: string[]) {
		const ref = doc(this.afs, 'chat-roles', 'admin');
		setDoc(ref, { phoneNumbers: adminRoles }, { merge: true });
	}

	async getSubscribers(subject: ChatSubject) {
		const q = query(collection(this.afs, 'chat-accounts') as CollectionReference, where('subscriptions', 'array-contains', subject.id));
		return (await getDocs(q)).docs.map(m => {
			const data = m.data();
			data['id'] = m.id;
			return data;
		});
	}
}
