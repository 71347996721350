import { collection, doc, setDoc } from '@angular/fire/firestore';
import { Notifications, SkillGroup } from '.';
import { MAS_Accounts_Service } from '../services';
import { AdvancedDebugging, AnyObject, DateTimeUtils } from '../utilities';

const advancedDebug = new AdvancedDebugging();
advancedDebug.setLogStyle('color: lightpurple;').closeNDeep(10);

export interface Account extends Addresses, Birthdays, Emails, MAS, Memberships, Names, PhoneNumbers, URLS {
	id: string;
}

export interface AccountAttendance {
	cycleName: string;
	scheduleId: string;
	scheduleName: string;
	startTime: string;
}

export type AccountSettings = {
	alternateTestCategory?: string;
	billing: 'true' | 'false';
	certifiedInstructor: 'true' | 'false';
	contractor?: 'true' | 'false';
	enableEmailReminders?: boolean;
	enableSMS?: boolean;
	gender: string;
	inactiveDate?: string;
	member: 'true' | 'false';
	profilePicture?: string;
	school?: string;
	status: string;
	timeCreated?: string;
	trialEndDate?: string;
};

export interface AccountEditMode {
	accountType?: 'active' | 'addParent' | 'child' | 'existing' | 'inactive' | 'legacy' | 'new' | 'schedules' | 'violations' | 'yas';
	editState: boolean;
	newAccountId?: string;
}

export interface AccountFilter {
	add: string;
	default: string;
	inactive: string;
	new: string;
	pending: string;
	schedules: string;
	violations: string;
	yas: string;
}

export interface Addresses {
	addresses?: {
		city: string;
		postalCode: string;
		region: string;
		streetAddress: string;
		type?: string;
	};
}

export interface AttendanceViolation {
	action?: 'waive' | 'fee';
	cycleName: string;
	endDate: string;
	id?: string;
	notifications: Notifications;
	scheduleId: string;
	summary: string;
}

export interface Birthdays {
	birthdays?: { text: string };
}

export interface Emails {
	emailAddresses?: {
		type: string;
		value: string;
	};
}

export interface gapi extends gapiToken, gapiUser {
	masAccountId?: string;
	userCredential?: any;
}

export interface gapiToken {
	token: {
		access_token: string;
		expiry_date: number;
		id_token: string;
		refresh_token: string;
		scope: string;
		token_type: string;
	};
}

export interface gapiUser {
	user: {
		email: string;
		id: string;
	};
}

export interface LinkedAccounts {
	accountId: string;
	data?: any;
	person: string;
	type: 'parent' | 'child';
}

export interface MAS {
	mas: {
		accountSettings: AccountSettings;
		acknowledgements?: string[];
		ataNumber?: string;
		gapi?: gapi;
		linkedAccounts?: LinkedAccounts[];
		memberAttendanceCumulative?: { [key: string]: number };
		nodeType: 'parent' | 'child';
		quickbooks?: QuickbooksCustomer;
		skills?: SkillGroup[];
		tournaments?: 'blue' | 'red' | 'silver' | 'gold' | 'none' | 'jahngsoo';
		triviaQuestionData?: TriviaQuestionData;
	};
}

export interface Memberships {
	memberships?: string[];
}

export interface Names {
	names: {
		familyName: string;
		givenName: string;
	};
}

export interface Node {
	billing: string;
	children?: unknown[];
	collapsedIcon?: 'pi pi-user';
	data: Account;
	expanded?: null;
	expandedIcon?: 'pi pi-users';
	key: string;
	label: string;
	status: string;
}

export interface PhoneNumbers {
	phoneNumbers?: {
		type: string;
		value: string;
	};
}

export interface QuickbooksCustomer {
	Active?: boolean;
	BillAddr?: {
		City?: string;
		Country?: string;
		CountrySubDivisionCode?: string;
		Line1?: string;
		PostalCode?: string;
	};
	DisplayName?: string;
	FamilyName?: string;
	FullyQualifiedName?: string;
	GivenName?: string;
	Id?: string;
	MetaData?: {
		CreateTime?: string;
		LastUpdatedTime?: string;
	};
	PrimaryEmailAddr: { Address: string };
	PrimaryPhone?: { FreeFormNumber: string };
	SyncToken: string;
	sparse: boolean;
}

export interface TournamentParticipation {
	tournamentDate: string;
	tournamentName: string;
}

export interface TriviaQuestionData {
	cumulativeCorrect: number;
	lastCorrectDate: string;
	lastDate: string;
}

export interface URLS {
	urls?: any;
}

export class MASAccountModel {
	[x: string]: any;
	#data: Account;

	constructor(data: Account, private accountService: MAS_Accounts_Service) {
		this.#data = data;
	}

	// Access to raw Firestore data
	get accountSettings() {
		return this.raw.mas.accountSettings;
	}

	get age(): number | null {
		const birthday = this.raw.birthdays?.text;
		if (!birthday) return null;
		return DateTimeUtils.calculateAge(birthday);
	}

	get alternateTestCategory(): string {
		return this.raw.mas.accountSettings.alternateTestCategory?.trim() || 'none';
	}

	set alternateTestCategory(value: string) {
		this.raw.mas.accountSettings.alternateTestCategory = value;
	}

	get birthdayAsDate(): Date | null {
		const val = this.raw.birthdays?.text;
		return val ? DateTimeUtils.parseDate(val) : null;
	}

	get birthdayDisplay(): string {
		const val = this.raw.birthdays?.text;
		return val ? DateTimeUtils.formatDateStringByMode(val, 'display') ?? '' : '';
	}

	get fullName(): string {
		const { givenName = '', familyName = '' } = this.#data.names || {};
		return `${givenName} ${familyName}`.trim();
	}

	get hasSchool(): boolean {
		const isChild = this.raw?.mas?.nodeType === 'child';
		const birthdayStr = this.raw?.birthdays?.text;
		const age = birthdayStr ? DateTimeUtils.calculateAge(birthdayStr) : null;

		return isChild && age !== null && age < 18;
	}

	get id(): string {
		return this.#data.id;
	}

	get isBilling(): boolean {
		return this.#data?.mas?.accountSettings?.billing === 'true';
	}

	get isChild(): boolean {
		return this.#data.mas.nodeType === 'child';
	}

	get isInactive(): boolean {
		return this.status === 'Inactive';
	}

	get isMember(): boolean {
		return this.#data.mas.accountSettings.member === 'true';
	}

	get isParent(): boolean {
		return this.#data.mas.nodeType === 'parent';
	}

	get isTrial(): boolean {
		return this.#data.mas.accountSettings.status === 'Trial';
	}

	get raw(): Account {
		return this.#data;
	}

	get status(): string {
		return this.#data.mas.accountSettings.status;
	}

	get trialEndDateAsDate(): Date | null {
		const val = this.raw.mas.accountSettings.trialEndDate;
		return val ? DateTimeUtils.parseDate(val) : null;
	}

	get trialEndDateDisplay(): string {
		const val = this.raw.mas.accountSettings.trialEndDate;
		return val ? DateTimeUtils.formatDateStringByMode(val, 'display') ?? '' : '';
	}

	applyEditModeType(type: 'child' | 'parent' | 'existing' | string | undefined) {
		if (type === 'child') this.raw.mas.nodeType = 'child';
		else if (type === 'parent') this.raw.mas.nodeType = 'parent';
	}

	autoFix(): string[] {
		const violations: string[] = [];
		const { mas } = this.raw;
		const { nodeType, linkedAccounts = [], accountSettings } = mas;
		const isChild = nodeType === 'child';
		const isParent = nodeType === 'parent';
		const isMember = accountSettings.member === 'true';
		const age = this.age;
		const tournaments = this.raw.mas.tournaments;

		if (isChild) {
			// Fix 1: LinkedAccounts should have exactly one parent
			if (linkedAccounts.length !== 1 && accountSettings.status === 'Active') {
				mas.linkedAccounts = linkedAccounts.filter((acc, index, self) => acc.type === 'parent' && self.findIndex(a => a.accountId === acc.accountId) === index);

				if (mas.linkedAccounts.length !== 1 && mas.accountSettings.status === 'Active') {
					violations.push('An active child must have exactly one valid parent-linked account.');
				}
			}

			// Fix 2: If only 1 link, but wrong type — set to parent
			if (mas.linkedAccounts?.length === 1 && mas.linkedAccounts[0].type !== 'parent') {
				mas.linkedAccounts[0].type = 'parent' as 'parent';
			}

			// Fix 3: Billing should be false
			if (accountSettings.billing !== 'false') {
				accountSettings.billing = 'false';
			}

			// Fix 4: Member should be true
			if (!isMember) {
				accountSettings.member = 'true';
			}

			// Fix school for under 18
			if (age !== null && age < 18 && !accountSettings.school) {
				violations.push('Child under 18 must have a school specified.');
			}
			if (this.raw.emailAddresses) {
				delete this.raw.emailAddresses;
			}
		}

		if (isParent) {
			if (linkedAccounts?.length > 0) {
				mas.linkedAccounts = linkedAccounts.map(acc => ({ ...acc, type: 'child' as 'child' })).filter((acc, index, self) => self.findIndex(a => a.accountId === acc.accountId) === index);
			}

			// Fix: Trial status not allowed for non-member
			if (!isMember && accountSettings.status === 'Trial') {
				accountSettings.status = 'Active';
			}

			// ✅ Fix: remove tournament data for non-member
			if (!isMember && tournaments) {
				delete mas.tournaments;
			}
		}

		if (isMember) {
			// Fix: Tournament missing — set to "none"
			if (!mas.tournaments) {
				mas.tournaments = 'none';
			}

			// Fix: Alternate Test Category — set to "none"
			if (!accountSettings.alternateTestCategory) {
				accountSettings.alternateTestCategory = 'none';
			}
		}

		mas.linkedAccounts = mas.linkedAccounts?.map(({ data, ...rest }) => rest) || [];

		// Anything still invalid?
		const result = this.validate();
		return result.violations;
	}

	clearLinks(): this {
		this.raw.mas.linkedAccounts = [];
		return this;
	}

	clearMemberships(): this {
		this.raw.memberships = [];
		return this;
	}

	clone(): MASAccountModel {
		const clonedRaw: Account = JSON.parse(JSON.stringify(this.raw));
		return new MASAccountModel(clonedRaw, this.accountService);
	}

	correctLinkName(account: MASAccountModel): this {
		const links = this.raw.mas?.linkedAccounts;
		if (Array.isArray(links)) {
			links.forEach(link => {
				if (link.accountId === account.raw.id) {
					link.person = account.fullName;
				}
			});
		}
		return this;
	}

	diffRaw(otherRaw: Account): AnyObject {
		return this._findDifferences(this.raw, otherRaw) || {};
	}

	diffSummaryRaw(otherRaw: Account): string[] {
		const changedPaths: string[] = [];
		this._findDifferencesSummary(this.raw, otherRaw, [], changedPaths);
		return changedPaths;
	}

	async getLinkedAccounts(): Promise<MASAccountModel[]> {
		const ids = this.#data.mas.linkedAccounts?.map(x => x.accountId).filter(Boolean);
		if (!ids?.length) return [];
		const accounts = await this.accountService.getAccountsByLinked(ids);
		return accounts.map(acc => new MASAccountModel(acc, this.accountService));
	}

	hasChangesRaw(otherRaw: Account): boolean {
		return this.diffSummaryRaw(otherRaw).length > 0;
	}

	hasChildren(): boolean {
		return !!this.#data.mas.linkedAccounts?.length;
	}

	private isDate(value: unknown): value is Date {
		return value instanceof Date && !isNaN(value.getTime());
	}

	linkAccount(account: Account): void {
		try {
			advancedDebug.log('MASAccountModel=>linkAccount=>account', account);

			const newLink: LinkedAccounts = {
				accountId: account.id,
				person: `${account.names.givenName} ${account.names.familyName}`,
				type: account.mas.nodeType as 'parent' | 'child',
			};
			advancedDebug.log('MASAccountModel=>linkAccount=>newLink', newLink);

			if (!this.raw.mas.linkedAccounts) {
				this.raw.mas.linkedAccounts = [];
			}

			// Avoid duplicate linking
			const alreadyLinked = this.raw.mas.linkedAccounts.some(link => link.accountId === newLink.accountId);

			if (!alreadyLinked) {
				this.raw.mas.linkedAccounts.push(newLink);
			}
			advancedDebug.log('MASAccountModel=>mas.linkAccount', this.raw.mas.linkedAccounts);
		} catch (error) {
			advancedDebug.captureError('MASAccountModel=>linkAccount', error, { account });
		}
	}

	removeAlternateTesting(): this {
		if (this.raw.mas.accountSettings.alternateTestCategory) {
			delete this.raw.mas.accountSettings.alternateTestCategory;
		}
		return this;
	}

	removeATANumber(): this {
		if (this.raw.mas.ataNumber) {
			delete this.raw.mas.ataNumber;
		}
		return this;
	}

	removeBirthday(): this {
		if (this.raw.birthdays) {
			delete this.raw.birthdays;
		}
		return this;
	}

	removeEmail(): this {
		if (this.raw.emailAddresses) {
			delete this.raw.emailAddresses;
		}
		return this;
	}

	removeLinkedAccounts(): this {
		if (this.raw.mas.linkedAccounts) {
			delete this.raw.mas.linkedAccounts;
		}
		return this;
	}

	removeLinkToChild(childId: string): this {
		this.raw.mas.linkedAccounts = this.raw.mas.linkedAccounts?.filter(link => link.accountId !== childId) ?? [];
		return this;
	}

	removeNotifications(): this {
		if (this.raw.mas.accountSettings.enableEmailReminders) {
			delete this.raw.mas.accountSettings.enableEmailReminders;
		}

		if (this.raw.mas.accountSettings.enableSMS) {
			delete this.raw.mas.accountSettings.enableSMS;
		}

		return this;
	}

	removeQuickbooks(): this {
		if (this.raw.mas.quickbooks) {
			delete this.raw.mas.quickbooks;
		}
		return this;
	}

	removeSchool(): this {
		if (this.raw.mas.accountSettings.school) delete this.raw.mas.accountSettings.school;
		return this;
	}

	removeTrialEndDate(): this {
		if (this.raw.mas.accountSettings.trialEndDate) delete this.raw.mas.accountSettings.trialEndDate;
		return this;
	}

	removeTriva(): this {
		if (this.raw.mas.triviaQuestionData) delete this.raw.mas.triviaQuestionData;
		return this;
	}

	removeTournaments(): this {
		if (this.raw.mas.tournaments) delete this.raw.mas.tournaments;
		return this;
	}

	save(state: 'existing' | 'new'): Promise<Account> {
		const collectionPath = 'mas-accounts';
		const firestore = this.accountService.firestore;

		return new Promise((resolve, reject) => {
			if (state === 'existing' && this.raw.id) {
				const ref = doc(firestore, collectionPath, this.raw.id);
				setDoc(ref, this.raw, { merge: true })
					.then(() => {
						advancedDebug.log('🔁 Updated existing account', this.raw);
						resolve(this.raw);
					})
					.catch(error => {
						advancedDebug.captureError('MASAccountModel.save()', error);
						reject(error);
					});
			} else {
				const collectionRef = collection(firestore, collectionPath);
				const newDocRef = doc(collectionRef);
				this.raw.id = newDocRef.id;
				setDoc(newDocRef, this.raw)
					.then(() => {
						advancedDebug.log('🆕 Added new account', this.raw);
						resolve(this.raw);
					})
					.catch(error => {
						advancedDebug.captureError('MASAccountModel.save()', error);
						reject(error);
					});
			}
		});
	}

	setInactiveDate(date: Date = new Date()): this {
		this.raw.mas.accountSettings.inactiveDate = date.toISOString().slice(0, 10);
		return this;
	}

	setTimeCreated(date: Date = new Date()): this {
		this.raw.mas.accountSettings.timeCreated = date.toISOString().slice(0, 10);
		return this;
	}

	static createEmptyParent(accountService: MAS_Accounts_Service): MASAccountModel {
		const emptyAccount: Account = {
			id: '',
			names: { givenName: '', familyName: '' },
			emailAddresses: { type: 'other', value: '' },
			phoneNumbers: { type: 'other', value: '' },
			addresses: {
				streetAddress: '',
				city: '',
				region: '',
				postalCode: '',
			},
			mas: {
				accountSettings: {
					billing: 'true',
					certifiedInstructor: 'false',
					member: 'false',
					gender: '',
					status: 'Active',
					school: '',
					trialEndDate: '',
					alternateTestCategory: '',
				},
				tournaments: 'none',
				ataNumber: '',
				nodeType: 'parent',
				linkedAccounts: [],
			},
			memberships: [],
		};
		return new MASAccountModel(emptyAccount, accountService);
	}

	toJSON(): Account {
		return this.#data;
	}

	updateFromForm(formValue: Partial<Account>) {
		this.raw.names = formValue.names ?? this.raw.names;
		this.raw.phoneNumbers = formValue.phoneNumbers ?? this.raw.phoneNumbers;
		this.raw.addresses = formValue.addresses ?? this.raw.addresses;

		if (formValue.emailAddresses?.value) {
			this.raw.emailAddresses = {
				...formValue.emailAddresses,
				value: formValue.emailAddresses.value.toLowerCase(),
			};
		} else {
			this.raw.emailAddresses = formValue.emailAddresses ?? this.raw.emailAddresses;
		}

		this.raw.mas.accountSettings = {
			...this.raw.mas.accountSettings,
			...formValue.mas?.accountSettings,
		};

		this.raw.mas.tournaments = formValue.mas?.tournaments ?? this.raw.mas.tournaments;
		this.raw.mas.ataNumber = formValue.mas?.ataNumber ?? this.raw.mas.ataNumber;

		if (this.raw.mas.accountSettings.member === 'true') {
			const bday = formValue.birthdays?.text;
			if (this.isDate(bday)) {
				this.raw.birthdays = { text: DateTimeUtils.formatDate(bday, 'date') };
			} else if (bday) {
				this.raw.birthdays = { text: bday };
			} else {
				delete this.raw.birthdays;
			}
		} else {
			delete this.raw.birthdays;
		}

		const trialDate = formValue.mas?.accountSettings?.trialEndDate;
		if (this.isDate(trialDate)) {
			this.raw.mas.accountSettings.trialEndDate = DateTimeUtils.formatDate(trialDate, 'date');
		} else if (typeof trialDate === 'string') {
			this.raw.mas.accountSettings.trialEndDate = trialDate;
		} else {
			delete this.raw.mas.accountSettings.trialEndDate;
		}

		return this;
	}

	validate(): { valid: boolean; violations: string[] } {
		const violations: string[] = [];
		const { mas, emailAddresses, birthdays, memberships } = this.raw;
		const { nodeType, linkedAccounts = [], accountSettings, triviaQuestionData } = mas;
		const isChild = nodeType === 'child';
		const isParent = nodeType === 'parent';
		const isMember = mas.accountSettings.member === 'true';
		const age = this.age;

		if (isChild) {
			if (linkedAccounts.length !== 1) {
				violations.push('Child must have exactly one linked account.');
			}
			if (Array.isArray(linkedAccounts) && linkedAccounts.some(acc => acc.type !== 'parent')) {
				violations.push('Child must only be linked to parent accounts.');
			}
			if (accountSettings.billing !== 'false') {
				violations.push('Child cannot have billing set to true.');
			}
			if (!isMember) {
				violations.push('Child must have member set to true.');
			}
			if (age !== null && age < 18 && !accountSettings.school) {
				violations.push('Child under 18 must have a school specified.');
			}
			if (emailAddresses) {
				violations.push('Child account cannot have email addresses.');
			}
		}

		if (isParent) {
			if (Array.isArray(linkedAccounts) && linkedAccounts.some(acc => acc.type !== 'child')) {
				violations.push('Parent must only be linked to child accounts.');
			}
			if (!isMember) {
				if (birthdays) {
					violations.push('Non-member parent should not have a birthday.');
				}
				if (accountSettings.status === 'Trial') {
					violations.push('Non-member parent cannot have status "Trial".');
				}
				if ('trialEndDate' in accountSettings && accountSettings.trialEndDate) {
					violations.push('Non-member parent should not have a trialEndDate.');
				}
				if (!isMember && this.raw.mas.tournaments) {
					violations.push('Non-member parent should not have tournament data.');
				}

				if (triviaQuestionData) {
					violations.push('Non-member parent should not have trivia questions.');
				}
				if (memberships) {
					violations.push('Non-member parent should not have memberships.');
				}
			}
			if ('school' in accountSettings && accountSettings.school) {
				violations.push('Parent account should not have a school assigned.');
			}
			if (!emailAddresses) {
				violations.push('Parent account should have an email address.');
			}
		}

		if (isMember) {
			if (!birthdays?.text) {
				violations.push('Member account should have a birthday.');
			}
			if (!mas.tournaments || mas.tournaments === 'none') {
				violations.push('Member account must have a valid tournament selection.');
			}
			if (!accountSettings.alternateTestCategory) {
				violations.push('Member account should have an alternate test category.');
			}
		}

		if (accountSettings.status === 'Inactive') {
			if (memberships && memberships.length > 0) {
				violations.push('Inactive account should not have a membership.');
			}
			if (linkedAccounts.length > 0) {
				violations.push('Inactive account should not have linked accounts.');
			}
			if (!accountSettings.inactiveDate) {
				violations.push('Inactive account should have an inactive date.');
			}
		}

		if (mas.linkedAccounts?.some(acc => 'data' in acc)) {
			violations.push('linkedAccounts should not include "data" property.');
		}

		return {
			valid: violations.length === 0,
			violations,
		};
	}

	// Shared private helpers
	private _findDifferences(a: any, b: any): any {
		if (typeof a !== 'object' || typeof b !== 'object' || a === null || b === null) {
			return a !== b ? a : undefined;
		}

		if (Array.isArray(a) && Array.isArray(b)) {
			return JSON.stringify(a) !== JSON.stringify(b) ? a : undefined;
		}

		const result: AnyObject = {};
		const keys = new Set([...Object.keys(a || {}), ...Object.keys(b || {})]);

		for (const key of keys) {
			const diff = this._findDifferences(a[key], b[key]);
			if (diff !== undefined) {
				result[key] = diff;
			}
		}

		return Object.keys(result).length > 0 ? result : undefined;
	}

	private _findDifferencesSummary(a: any, b: any, path: string[] = [], changedPaths: string[]) {
		if (typeof a !== 'object' || typeof b !== 'object' || a === null || b === null) {
			if (a !== b) changedPaths.push(path.join('.'));
			return;
		}

		if (Array.isArray(a) && Array.isArray(b)) {
			if (JSON.stringify(a) !== JSON.stringify(b)) changedPaths.push(path.join('.'));
			return;
		}

		const keys = new Set([...Object.keys(a || {}), ...Object.keys(b || {})]);
		for (const key of keys) {
			this._findDifferencesSummary(a[key], b[key], [...path, key], changedPaths);
		}
	}
}
