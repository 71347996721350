import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { gapiSettings } from 'src/environments/gapisettings';
import { GeocoderResponse } from '../models/geocoder.model';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class GeocodingService {
	constructor(private http: HttpClient) {}

	geocodeLatLng(location: google.maps.LatLngLiteral): Promise<GeocoderResponse> {
		return new Promise((resolve, reject) => {
			if (!window.google || !window.google.maps) {
				reject(new Error('Google Maps API not loaded.'));
				return;
			}

			const geocoder = new google.maps.Geocoder();
			geocoder.geocode({ location: location }, (results, status) => {
				const response = new GeocoderResponse(status, results!);
				resolve(response);
			});
		});
	}

	getLocation(term: string): Observable<GeocoderResponse> {
		const url = `https://maps.google.com/maps/api/geocode/json?address=${term}&sensor=false&key=${gapiSettings.Maps}`;

		return this.http.get<GeocoderResponse>(url);
	}
}
