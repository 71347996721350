import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { getAuth } from '@angular/fire/auth';
import { collection, CollectionReference, collectionSnapshots, doc, DocumentData, Firestore, getDocs, query, setDoc, where } from '@angular/fire/firestore';
import { limit } from 'firebase/firestore';
import { firstValueFrom, lastValueFrom, map, Observable } from 'rxjs';
import { Account, Schedule } from '../models';
import { Product } from '../models/product.models';

@Injectable({
	providedIn: 'root',
})
export class SandboxService {
	status: string[] = ['OUTOFSTOCK', 'INSTOCK', 'LOWSTOCK'];

	productNames: string[] = [
		'Bamboo Watch',
		'Black Watch',
		'Blue Band',
		'Blue T-Shirt',
		'Bracelet',
		'Brown Purse',
		'Chakra Bracelet',
		'Galaxy Earrings',
		'Game Controller',
		'Gaming Set',
		'Gold Phone Case',
		'Green Earbuds',
		'Green T-Shirt',
		'Grey T-Shirt',
		'Headphones',
		'Light Green T-Shirt',
		'Lime Band',
		'Mini Speakers',
		'Painted Phone Case',
		'Pink Band',
		'Pink Purse',
		'Purple Band',
		'Purple Gemstone Necklace',
		'Purple T-Shirt',
		'Shoes',
		'Sneakers',
		'Teal T-Shirt',
		'Yellow Earbuds',
		'Yoga Mat',
		'Yoga Set',
	];

	constructor(private http: HttpClient, private firestore: Firestore) {}

	getProducts() {
		const result = this.http.get<any>('assets/products.json');

		const lastValue = lastValueFrom(result);

		return lastValue
			.then(res => <Product[]>res.data)
			.then(data => {
				return data;
			});
	}

	generateId() {
		let text = '';
		const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

		for (let i = 0; i < 5; i++) {
			text += possible.charAt(Math.floor(Math.random() * possible.length));
		}

		return text;
	}

	// A function that returns a Promise that resolves after a specified duration
	waitAndRespond(duration: number): Promise<string> {
		return new Promise((resolve, reject) => {
			if (duration < 0) {
				// Reject the promise if the duration is negative
				reject('Duration must be a positive number.');
				return;
			}

			setTimeout(() => {
				resolve(`Completed after ${duration} milliseconds`);
			}, duration);
		});
	}

	chuckNorrisJokes() {
		return this.http.get('https://api.chucknorris.io/jokes/random');
	}

	getExternalAPI(url: string) {
		const stream = this.http.get(url);
		return firstValueFrom(stream);
	}

	async getActiveAccountsAsPromise(): Promise<Account[]> {
		const q = query(collection(this.firestore, 'mas-accounts') as CollectionReference<Account>, where('mas.accountSettings.status', '==', 'Active'));
		const snapshot = await getDocs(q);
		return snapshot.docs.map(m => ({ ...m.data(), id: m.id }));
	}

	async getProgramsAsPromise(): Promise<Account[]> {
		const q = query(collection(this.firestore, 'mas-programs') as CollectionReference<Account>);
		const snapshot = await getDocs(q);
		return snapshot.docs.map(m => ({ ...m.data(), id: m.id }));
	}

	getActiveAccountsAsObservable(): Observable<Account[]> {
		return collectionSnapshots<Account>(
			query<Account, DocumentData>(collection(this.firestore, 'mas-accounts') as CollectionReference<Account>, where('mas.accountSettings.status', '==', 'Active'))
		).pipe(
			map(changes => {
				return changes.map(a => {
					const data = a.data();
					data.id = a.id;
					return data;
				});
			})
		);
	}

	async getMAKSchedulesClosed() {
		try {
			const auth = getAuth();
			if (!auth.currentUser) {
				console.error('User is not authenticated!');
				return;
			}

			const q = query<Schedule, DocumentData>(
				collection(this.firestore, 'mas-schedules') as CollectionReference<Schedule>,
				where('eventType', '==', 'closed'),
				where('summary', '==', 'MAK'),
				limit(25)
			);

			const qDocs = await getDocs(q);
			if (qDocs.empty) return [];

			const data = qDocs.docs.map(m => m.data());

			for (const each of data) {
				each.eventType = 'default';
				await this.setScheduleById(each.id, each);
			}

			return data;
		} catch (error) {
			if (window.location.hostname == 'localhost') console.error('getMAKSchedulesClosed=>error', error);
			throw error;
		}
	}

	async setScheduleById(id: string, payload: Schedule, merge: boolean = true) {
		const schedule = doc(this.firestore, 'mas-schedules', id);
		return await setDoc(schedule, payload, { merge: merge });
	}
}
