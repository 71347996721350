export class GeocoderResponse {
	status!: string;
	error_message!: string;
	results!: google.maps.GeocoderResult[];

	constructor(status: string, results: google.maps.GeocoderResult[]) {
		this.status = status;
		this.results = results;
	}
}

export interface BubbleOptions {
	center: {
		lat: number;
		lng: number;
	};
	options: {
		strokeColor: string;
		strokeOpacity: number;
		strokeWeight: number;
		fillColor: string;
		fillOpacity: number;
		radius: number;
	};
	info: string;
}
