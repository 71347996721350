<div>
	<p-card>
		<div style="text-align: center">Welcome</div>
		<div style="text-align: center">
			<p-button
				label="Login with Google"
				icon="pi pi-lock-open"
				type="button"
				class="ml-2"
				(click)="this.onContinueWithGoogleClick()"></p-button>
		</div>
	</p-card>
</div>
