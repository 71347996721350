import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GoogleIdentityService } from './services/identity.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
	public user: any;
	constructor(public identityService: GoogleIdentityService, private router: Router) {}
	ngOnInit() {
		this.identityService.getUser.subscribe(async user => {
			this.user = user;

			if (user?.email) {
				this.identityService.initToken(user.email);
				this.router.navigate(['accounts']);
			} else {
				this.router.navigate(['sign-in']);
			}
		});
	}
}
