import { Injectable } from '@angular/core';
import { CollectionReference, DocumentData, Firestore, collection, collectionGroup, doc, getDocs, query, setDoc, where, writeBatch } from '@angular/fire/firestore';
import { FirestoreError, getDoc, orderBy } from 'firebase/firestore';
import { MasAccountConverterService } from '../converters/mas-accounts.converter';
import { Account, Competitor, MASAccountModel, Program, TournamentStanding } from '../models';
import { MAS_Programs_Service, MAS_Schedules_Service } from '../services/';
import { AdvancedDebugging } from '../utilities';

@Injectable({
	providedIn: 'root',
})
export class UtilitiesService {
	private advancedDebug = new AdvancedDebugging();
	constructor(private afs: Firestore, private programsService: MAS_Programs_Service, private schedulesService: MAS_Schedules_Service, private masAccountConverter: MasAccountConverterService) {}
	setCompetitor(competitor: Competitor, ataNumber: string) {
		const ref = doc(this.afs, 'mas-competitors', ataNumber);
		setDoc(ref, competitor, { merge: true });
	}

	setAccount(account: Account) {
		const ref = doc(this.afs, 'mas-accounts', account.id);
		setDoc(ref, account, { merge: true });
	}

	setProgram(program: Program) {
		const ref = doc(this.afs, 'mas-programs', program.id);
		setDoc(ref, program, { merge: true });
	}

	async getCompetitorsAsPromise(): Promise<Competitor[]> {
		const q = query<Competitor, DocumentData>(collection(this.afs, 'mas-competitors') as CollectionReference<Competitor>);
		return await getDocs(q).then(data => data.docs.map(m => m.data()));
	}

	setMapData(mapData: any) {
		const ref = doc(this.afs, 'mas-parameters', 'mapData');
		setDoc(ref, mapData, { merge: true });
	}

	async getAccountAsPromise(givenName: string, familyName: string): Promise<Account[]> {
		const q = query<Account, DocumentData>(
			collection(this.afs, 'mas-accounts') as CollectionReference<Account>,
			where('names.givenName', '==', givenName),
			where('names.familyName', '==', familyName)
		);

		return await getDocs(q).then(data => data.docs.map(m => m.data()));
	}

	async getAccountByATANumberAsPromise(ataNumber: string) {
		try {
			// Create the query
			const accountCollection = collection(this.afs, 'mas-accounts') as CollectionReference<Account>;
			const q = query<Account, DocumentData>(accountCollection, where('mas.ataNumber', '==', ataNumber));

			// Fetch the documents
			const querySnapshot = await getDocs(q);

			// Get the first document's ID if exists
			const firstDoc = querySnapshot.docs[0];
			return firstDoc ? firstDoc.data() : undefined;
		} catch (error) {
			console.error('Error fetching account by ATA number:', error);
			throw error;
		}
	}

	// openai suggestion for future consideration
	async saveToFirestore(dataArray: any[]) {
		const promises = dataArray.map(async item => {
			const docRef = doc(this.afs, 'test', item.id);
			await setDoc(docRef, item);
		});

		try {
			await Promise.all(promises);
			console.log('All items saved to Firestore!');
		} catch (error) {
			console.error('Error writing to Firestore:', error);
		}
	}

	async setAccountTournament(id: string, payload: any) {
		const path = `mas-accounts/${id}/mas-accounts-tournaments`;
		const ref = doc(this.afs, path, payload.tournamentDate);
		setDoc(ref, payload, { merge: true });
	}

	async setAccountStanding(id: string, payload: TournamentStanding) {
		const path = `mas-accounts/${id}/mas-accounts-standings`;
		const ref = doc(this.afs, path, payload.event);
		setDoc(ref, payload, { merge: true });
	}

	async deleteAllMasAccountsStandings() {
		try {
			const accountsCollection = collectionGroup(this.afs, 'mas-accounts-standings') as CollectionReference<Account>;
			const accountsQuery = query<Account, DocumentData>(accountsCollection);
			const accountsSnapshot = await getDocs(accountsQuery);
			accountsSnapshot.docs.forEach(async doc => {
				if (doc.ref.parent.parent?.id) await this.deleteStandingsForAccount(doc.ref.parent.parent?.id);
			});
		} catch (error) {
			console.error('Error deleting MAS accounts standings:', error);
		}
	}

	private async deleteStandingsForAccount(accountId: string) {
		try {
			const standingsCollection = collection(this.afs, `mas-accounts/${accountId}/mas-accounts-standings`) as CollectionReference<any>;
			const standingsQuery = query<any, DocumentData>(standingsCollection);
			const standingsSnapshot = await getDocs(standingsQuery);

			const batch = writeBatch(this.afs);
			standingsSnapshot.docs.forEach(standing => {
				const ref = doc(this.afs, `mas-accounts/${accountId}/mas-accounts-standings`, standing.id);
				batch.delete(ref);
			});

			await batch.commit();
		} catch (error) {
			console.error(`Error deleting standings for account ${accountId}:`, error);
		}
	}

	async getMapDataAsPromise() {
		const ref = doc(this.afs, 'mas-parameters', 'mapData');
		return (await getDoc(ref)).data();
	}

	async addToCollection(path: string, payload: any, id: string, useMerge: boolean = true) {
		const ref = doc(this.afs, path, id);
		setDoc(ref, payload, { merge: useMerge });
	}

	async getProgramsByArray(array: string[]): Promise<Program[]> {
		try {
			const programsCollection = collection(this.afs, 'mas-programs') as CollectionReference<Program>;
			const programsQuery = query<Program, DocumentData>(programsCollection, where('id', 'in', array));
			const querySnapshot = await getDocs(programsQuery);
			return querySnapshot.docs.map(doc => doc.data() as Program);
		} catch (error) {
			const firestoreError = error as FirestoreError;
			console.error('Error fetching programs:', firestoreError);
			throw new Error('Failed to fetch programs: ' + firestoreError.message);
		}
	}

	async getAccountsToConvert(array: string[]): Promise<Account[]> {
		try {
			const accountsCollection = collection(this.afs, 'mas-accounts') as CollectionReference<Account>;
			const accountsQuery = query<Account, DocumentData>(accountsCollection, where('memberships', 'array-contains-any', array));
			const querySnapshot = await getDocs(accountsQuery);
			return querySnapshot.docs.map(doc => doc.data() as Account);
		} catch (error) {
			const firestoreError = error as FirestoreError;
			console.error('Error fetching accounts:', firestoreError);
			throw new Error('Failed to fetch accounts: ' + firestoreError.message);
		}
	}

	async getActiveAccountForInspect(): Promise<MASAccountModel[]> {
		try {
			const accountsCollection = collection(this.afs, 'mas-accounts') as CollectionReference<Account>;
			const accountsQuery = query<Account, DocumentData>(accountsCollection, where('mas.accountSettings.status', '==', 'Active'));
			const querySnapshot = await getDocs(accountsQuery);

			return querySnapshot.docs.map(doc =>
				this.masAccountConverter.fromRaw({
					...doc.data(),
					id: doc.id,
				})
			);
		} catch (error) {
			const firestoreError = error as FirestoreError;
			this.advancedDebug.captureError('UtilitiesService=>getActiveAccountsToConvert', error);
			throw new Error('Failed to fetch accounts: ' + firestoreError.message);
		}
	}

	async getActiveAccountsByModel(): Promise<MASAccountModel[]> {
		try {
			const q = query(collection(this.afs, 'mas-accounts') as CollectionReference<Account>, where('mas.accountSettings.status', '==', 'Active'));
			const snapshot = await getDocs(q);

			return snapshot.docs.map(doc =>
				this.masAccountConverter.fromRaw({
					...doc.data(),
					id: doc.id,
				})
			);
		} catch (error) {
			this.advancedDebug.captureError('getActiveAccountsByModel', error);
			throw error;
		}
	}

	async getBirthdayList(): Promise<Account[]> {
		try {
			const accountsCollection = collection(this.afs, 'mas-accounts') as CollectionReference<Account>;
			const accountsQuery = query<Account, DocumentData>(
				accountsCollection,
				where('mas.accountSettings.status', '==', 'Active'),
				where('birthdays.text', '!=', null),
				orderBy('birthdays.text')
			);
			const querySnapshot = await getDocs(accountsQuery);

			// Map and add the 'dayOfMonth' property
			return querySnapshot.docs
				.map(doc => {
					const account = doc.data() as Account;
					if (account.birthdays?.text) {
						// Manually extract the day of the month from the string (YYYY-MM-DD)
						const dayOfMonth = parseInt(account.birthdays.text.split('-')[2], 10); // Get the day part
						return { ...account, dayOfMonth };
					}
					return account;
				})
				.filter(account => account.birthdays?.text !== undefined); // Filter out any accounts without a birthday
		} catch (error) {
			const firestoreError = error as FirestoreError;
			console.error('Error fetching accounts:', firestoreError);
			throw new Error('Failed to fetch accounts: ' + firestoreError.message);
		}
	}

	async getMemberList(): Promise<Account[]> {
		try {
			const accountsCollection = collection(this.afs, 'mas-accounts') as CollectionReference<Account>;
			const accountsQuery = query<Account, DocumentData>(accountsCollection, where('mas.accountSettings.status', '==', 'Active'), where('mas.accountSettings.member', '==', 'true'));
			const querySnapshot = await getDocs(accountsQuery);

			return querySnapshot.docs.map(doc => doc.data() as Account).filter(account => account.mas.ataNumber !== undefined); // Check existence and content
		} catch (error) {
			const firestoreError = error as FirestoreError;
			console.error('Error fetching accounts:', firestoreError);
			throw new Error('Failed to fetch accounts: ' + firestoreError.message);
		}
	}

	async getSchedulesForUpdate() {
		const schedules = await this.schedulesService.getSchedulesAsPromise(true);

		const updatedSchedules = await this.updateEventsWithProgramId(schedules);

		updatedSchedules.forEach(schedule => {
			this.schedulesService.setScheduleById(schedule.id, schedule, true);
		});
	}

	async updateEventsWithProgramId(events: any[]): Promise<any[]> {
		try {
			const programs = await this.programsService.getProgramsAsPromise();

			return events
				.map(event => {
					const matchedProgram = programs.find(program => event.summary === program.name || event.summary === program.testCategory);

					return matchedProgram ? { ...event, programId: matchedProgram.id } : null;
				})
				.filter(event => event !== null); // Remove events without a matching program
		} catch (error) {
			console.error('Error updating events:', error);
			return [];
		}
	}
}
