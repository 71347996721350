export interface AccountQuickbooks {
	Active?: boolean;
	BillAddr?: { City?: string; Country?: string; CountrySubDivisionCode?: string; Line1?: string; PostalCode?: string };

	DisplayName?: string;

	FamilyName?: string;
	FullyQualifiedName?: string;

	GivenName?: string;

	Id?: string;
	MetaData?: { CreatedTime?: string; LastUpdatedTime?: string };
	PrimaryEmailAddr: { Address: string };

	PrimaryPhone?: { FreeFormNumber: string };
	SyncToken: string;
	sparse: boolean;
}

export interface AccountQuickbooksArray {
	Customer: AccountQuickbooks[];
}

export interface QBToken {
	access_token: string;
	expires_in: number;
	expires_time?: number;
	lastCustomerUpdate: number;
	refresh_expires_time: number;
	refresh_time?: number;
	refresh_token: string;
	server_time: number;
	token_type: string;
	x_refresh_token_expires_in: number;
	status: 'refreshing' | 'authorizing' | 'idle';
}
