import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, lastValueFrom, of, take } from 'rxjs';
import { firebase } from 'src/environments/environment';
import { gapiSettings } from 'src/environments/gapisettings';
import { GoogleIdentityService } from './identity.service';

@Injectable({
	providedIn: 'root',
})
export class Firebase_Functions_Service {
	private isLocalhost = window.location.hostname === 'localhost';
	private functionsAPIEndpoint = this.isLocalhost ? firebase.Cfg.emulatorEndpoint : firebase.Cfg.liveEndpoint;
	constructor(private http: HttpClient, private identityService: GoogleIdentityService) {}

	/*************************************************************************
	 * GAPI API
	 *************************************************************************/

	// Using GAPI authorization URL get token information an store it in Firecloud
	async getAccessToken(gapiRefreshToken: string): Promise<any> {
		try {
			const result$ = this.identityService.getUser.pipe(take(1));
			const user = await lastValueFrom(result$);
			const token = await user?.getIdToken();
			if (!token) {
				return { status: 400, message: 'No token available' };
			}

			const headers = new HttpHeaders({
				token,
				gapirefreshtoken: gapiRefreshToken,
			});

			const response$ = this.http.get<string>(`${this.functionsAPIEndpoint}/gapi/accessToken`, {
				headers,
				responseType: 'text' as 'json',
			});

			const response = await lastValueFrom(response$);

			if (window.location.hostname == 'localhost') {
				console.log('response', response);
			}

			return response;
		} catch (error) {
			console.error('Failed to fetch access token:', error);
			return { status: 500, message: 'Internal Server Error', details: error };
		}
	}

	// Add member to group given in request header
	getAddMember(accessToken: string, group: string, email: string): Observable<any> {
		if (this.isLocalhost) console.log('identityService', this?.identityService);

		const token = this.identityService.getUserIdToken;

		if (typeof token === 'string') {
			const headers = new HttpHeaders({
				token: token,
				bearer: accessToken,
				group: group,
				email: email,
				key: gapiSettings.Cfg.apiKey,
			});
			return <Observable<any>>this.http.get(`${this.functionsAPIEndpoint}/gapi/addMember`, {
				headers,
				responseType: 'text',
			});
		} else {
			return of({ status: 400 });
		}
	}
	async getEventsFromGoogleCalendar(accessToken: string, filter: string): Promise<any> {
		const d = new Date();
		const start = d.toISOString();
		const token = this.identityService.getUserIdToken;

		if (typeof token !== 'string') {
			throw new Error('Invalid token');
		}

		const headers = new HttpHeaders({
			token: token,
			bearer: accessToken,
			filter: filter,
			start: start,
			key: gapiSettings.Cfg.apiKey,
			calendar: gapiSettings.CalendarId,
		});

		try {
			const response = await lastValueFrom(
				this.http.get<any>(`${this.functionsAPIEndpoint}/gapi/events`, {
					headers,
					responseType: 'json', // Return as JSON
				})
			);
			return response || [];
		} catch (error) {
			console.error('Error fetching Google Calendar events:', error);
			throw new Error('Failed to fetch events from Google Calendar');
		}
	}

	// Get an URL to initialize GAPI authorization routine
	async getGoogleLogin() {
		const result = this.identityService.getUser.pipe(take(1));
		const user = await lastValueFrom(result);
		const token = await user?.getIdToken();

		if (token) {
			const headers = new HttpHeaders({ token: token });
			const result = this.http.get(`${this.functionsAPIEndpoint}/gapi/googleLogin`, {
				headers,
				responseType: 'text',
			});

			const url = await lastValueFrom(result);

			window.open(url, '_blank');
			return true;
		} else {
			return;
		}
	}

	// Get Google Group information
	getGroup(accessToken: string, group: string): Observable<any> {
		const token = this.identityService.getUserIdToken;

		if (typeof token === 'string') {
			const headers = new HttpHeaders({
				token: token,
				bearer: accessToken,
				group: group,
				key: gapiSettings.Cfg.apiKey,
			});
			return <Observable<any>>this.http.get(`${this.functionsAPIEndpoint}/gapi/group`, {
				headers,
				responseType: 'text',
			});
		} else {
			return of({ status: 400 });
		}
	}

	// Get Google Group Members information
	getMembers(accessToken: string, group: string): Observable<any> {
		const token = this.identityService.getUserIdToken;

		if (typeof token === 'string') {
			const headers = new HttpHeaders({
				token: token,
				bearer: accessToken,
				group: group,
				key: gapiSettings.Cfg.apiKey,
			});
			return <Observable<any>>this.http.get(`${this.functionsAPIEndpoint}/gapi/members`, {
				headers,
				responseType: 'text',
			});
		} else {
			return of({ status: 400 });
		}
	}

	// Remove member from group given in request header
	removeGroupMember(accessToken: string, group: string, email: string): Observable<any> {
		const token = this.identityService.getUserIdToken;

		if (typeof token === 'string') {
			const headers = new HttpHeaders({
				token: token,
				bearer: accessToken,
				group: group,
				email: email,
				key: gapiSettings.Cfg.apiKey,
			});
			return <Observable<any>>this.http.get(`${this.functionsAPIEndpoint}/gapi/removeMember`, {
				headers,
				responseType: 'text',
			});
		} else {
			return of({ status: 400 });
		}
	}

	createSharedContact(group: { name: string; email: string }, accessToken: string) {
		const token = this.identityService.getUserIdToken;

		if (typeof token === 'string') {
			const headers = new HttpHeaders({
				token: token,
				bearer: accessToken,
				'Content-Type': 'application/json',
			});

			const result = this.http
				.post(`${this.functionsAPIEndpoint}/gapi/createSharedContact`, group, {
					headers,
					responseType: 'text',
				})
				.pipe(
					catchError(error => {
						throw error;
					})
				);

			return lastValueFrom(result);
		} else {
			return of({ status: 400 });
		}
	}

	getDirectory(accessToken: string): Observable<any> {
		const token = this.identityService.getUserIdToken;

		if (typeof token === 'string') {
			const headers = new HttpHeaders({ token: token, bearer: accessToken });

			return <Observable<any>>this.http.get(`${this.functionsAPIEndpoint}/gapi/directory`, {
				headers,
				responseType: 'text',
			});
		} else {
			return of({ status: 400 });
		}
	}

	removeSharedContact(accessToken: string) {
		const token = this.identityService.getUserIdToken;

		if (typeof token === 'string') {
			const headers = new HttpHeaders({ token: token, bearer: accessToken });

			return <Observable<any>>this.http.get(`${this.functionsAPIEndpoint}/gapi/directory`, {
				headers,
				responseType: 'text',
			});
		} else {
			return of({ status: 400 });
		}
	}

	/* 	sendCancelationEmail(list: string[], schedule: Schedule) {
		if (list.length > 0) {
			const email: Email = {
				sender: 'taraharner@yongsa.net',
				to: 'accounts@yongsa.net',
				bcc: list,
				subject: `${schedule.summary} on ${schedule.start.dateTime} was removed from the schedule`,
				text: `Your account had a reservation for this date. We wanted to let you know that we removed it from the schedule.`,
			};

			this.sendEmail(email);
		}
	} */

	/*************************************************************************
	 * Utilities API
	 *************************************************************************/
	getEnviroment(): Observable<any> {
		return <Observable<any>>this.http.get(`${this.functionsAPIEndpoint}/currentEnvironment`);
	}

	getFirecloudDocuments(): Observable<any> {
		return <Observable<any>>this.http.get(`${this.functionsAPIEndpoint}/sandbox/documents`);
	}
}
