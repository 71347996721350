import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class QuickbooksInterceptor implements HttpInterceptor {
	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(req).pipe(
			catchError((error: any) => {
				if (error instanceof HttpErrorResponse && error.status === 401 && error.error?.authUrl) {
					console.warn('🔐 Opening QB reauth popup...');

					// ⏳ Option 1: delay using setTimeout to escape Angular zone
					setTimeout(() => {
						const popup = window.open(error.error.authUrl, '_blank', 'width=600,height=700');

						if (!popup) {
							console.warn('❌ Popup blocked. Please allow popups.');
						}
					}, 100); // small delay to avoid popup blocking

					return throwError(() => new Error('Reauthorization required'));
				}

				return throwError(() => error);
			})
		);
	}
}
