import { Component, OnInit } from '@angular/core';
import { GoogleIdentityService } from 'src/app/services/identity.service';
import { Router } from '@angular/router';
import { Firebase_Functions_Service } from 'src/app/services/functions.service';
import { CustomFunctions, throwCustomError } from 'src/app/models/global.model';

@Component({
	selector: 'app-sign-in',
	templateUrl: './sign-in.component.html',
	styleUrls: ['./sign-in.component.css'],
})
export class SignInComponent implements OnInit {
	constructor(private identityService: GoogleIdentityService, private router: Router, private firebasefirebaseFunctions: Firebase_Functions_Service) {}

	ngOnInit() {
		this.identityService.getUser.subscribe(user => {
			if (user) this.router.navigate(['accounts']);
		});
	}

	async onContinueWithGoogleClick() {
		await this.identityService.signInPopup().catch(error => {
			const arrayFromObject = CustomFunctions.extractUniqueValues(this, ['email', 'projectId', 'host', 'href']);
			arrayFromObject.push({ codePath: 'onContinueWithGoogleClick=>onSubmit' });
			throwCustomError(error, arrayFromObject);
		});
	}
}
