export interface PiePartner {
	css: { 'background-color': string; color: string };
	foundation?: string;
	fundraisers?: Fundraiser;
	hasYAS: boolean;
	id: string;
	logo?: string;
	name: string;
	slogan?: string;
	trialOffer?: string;
	website?: string;
}

export interface Fundraiser {
	deadline: string;
	endDate: string;
	kickoff: string;
	orientations: string[];
	startDate: string;
}

export interface PieContribution {
	id?: string;
	description: string;
	dollars: number;
	hours: number;
	name: string;
	timeIn: string | Date;
	type: string;
}
