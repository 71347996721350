export const sandboxFirebase = {
	gregharner: {
		apiKey: 'AIzaSyCnnKIycurp2XrofiKuOOhx17g0U2vAOSk',
		authDomain: 'mas-development-53ac7.firebaseapp.com',
		projectId: 'mas-development-53ac7',
		storageBucket: 'mas-development-53ac7.appspot.com',
		messagingSenderId: '472191207902',
		appId: '1:472191207902:web:fa19a266bfccba0baa354f',
		measurementId: 'G-99NDJ28KET',
		apiEndpoint: 'https://us-central1-mas-development-53ac7.cloudfunctions.net',
	},
};
