import { Injectable } from '@angular/core';
import { addDoc, collection, CollectionReference, deleteDoc, doc, DocumentData, Firestore, getDocs, orderBy, query, setDoc, where } from '@angular/fire/firestore';
import { PieContribution } from '../models/';
import { PIE_Partners_Service } from '.';

@Injectable({
	providedIn: 'root',
})
export class PIE_Contributions_Service {
	constructor(private afs: Firestore, private partner_Service: PIE_Partners_Service) {}

	async addContribution(payload: PieContribution) {
		const ref = collection(this.afs, 'pie-contributions');
		const doc = await addDoc(ref, payload);
		return doc.id;
	}
	async getContributions() {
		const q = query(collection(this.afs, 'pie-contributions'), where('name', '==', this.partner_Service.partner.name));
		return await getDocs(q).then(document => document);
	}
	async getImpactBySchool(school: string) {
		const q = query<PieContribution, DocumentData>(collection(this.afs, 'pie-contributions') as CollectionReference<PieContribution>, where('name', '==', school), orderBy('timeIn'));
		return await getDocs(q);
	}

	async setContribution(id: string, payload: PieContribution) {
		const ref = doc(this.afs, 'pie-contributions', id);
		setDoc(ref, payload, { merge: true });
		return id;
	}
	deleteContribution(id: string) {
		const ref = doc(this.afs, 'pie-contributions', id);
		deleteDoc(ref);
	}

	async getImpact() {
		const q = query<PieContribution, DocumentData>(collection(this.afs, 'pie-contributions') as CollectionReference<PieContribution>);
		return (await getDocs(q)).docs.map(m => m.data());
	}
}
