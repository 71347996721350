import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GoogleIdentityService } from 'src/app/services/identity.service';
import { CustomFunctions, throwCustomError } from 'src/app/models/global.model';

@Component({
	selector: 'app-navbar',
	templateUrl: './navbar.component.html',
	styleUrls: ['./navbar.component.css'],
})
export class NavbarComponent implements OnInit {
	public loggedInUser!: string;
	public isLoggedIn: boolean = false;

	constructor(private identityService: GoogleIdentityService, private router: Router) {}

	ngOnInit() {}

	onLogoutClick() {
		this.identityService.signOut();
		this.router.navigate(['sign-in']).catch(error => {
			const arrayFromObject = CustomFunctions.extractUniqueValues(this, ['email', 'projectId', 'host', 'href']);
			arrayFromObject.push({ codePath: 'NavbarComponent=>onLogoutClick' });
			throwCustomError(error, arrayFromObject);
		});
	}

	navigateByButton(button: string) {
		this.router.navigate([button]).catch(error => {
			const arrayFromObject = CustomFunctions.extractUniqueValues(this, ['email', 'projectId', 'host', 'href']);
			arrayFromObject.push({ codePath: 'NavbarComponent=>navigateByButton' });
			throwCustomError(error, arrayFromObject);
		});
	}
}
