import { Injectable, Injector } from '@angular/core';
import { Account, MASAccountModel } from '../models';
import { MAS_Accounts_Service } from '../services';

@Injectable({ providedIn: 'root' })
export class MasAccountConverterService {
	private _accountsService!: MAS_Accounts_Service;

	constructor(private injector: Injector) {}

	private get accountsService(): MAS_Accounts_Service {
		if (!this._accountsService) {
			this._accountsService = this.injector.get(MAS_Accounts_Service);
		}
		return this._accountsService;
	}

	fromRaw(raw: Account): MASAccountModel {
		return new MASAccountModel(raw, this.accountsService);
	}

	toRaw(model: MASAccountModel): Account {
		return model.toJSON();
	}
}
