import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { getApp, initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { connectAuthEmulator, getAuth, provideAuth } from '@angular/fire/auth';
import { Firestore, connectFirestoreEmulator, getFirestore, initializeFirestore, provideFirestore } from '@angular/fire/firestore';
import { getFunctions, provideFunctions } from '@angular/fire/functions';
import { connectStorageEmulator, getStorage, provideStorage } from '@angular/fire/storage';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular';
import { Functions, connectFunctionsEmulator } from 'firebase/functions';
import { API_KEY, GoogleSheetsDbService } from 'ng-google-sheets-db';
import { firebase } from 'src/environments/environment';
import { gapiSettings } from '../../environments/gapisettings';
import { AppComponent } from '../app.component';
import { NavbarComponent } from '../components/navbar/navbar.component';
import { SignInComponent } from '../components/sign-in/sign-in.component';
import { AuthGuard } from '../guards/auth.guard';
import { ErrorInterceptor } from '../interceptors/error.interceptor.service';
import { QuickbooksInterceptor } from '../interceptors/quickbooks.interceptor';
import { AppRoutingModule } from '../routing/app.routing.module';
import { GoogleIdentityService } from '../services/identity.service';
import { NGPrimeModule } from './ngprime.module';

const isLocalhost = window.location.hostname === 'localhost';

@NgModule({
	declarations: [AppComponent, SignInComponent, NavbarComponent],
	imports: [
		AppRoutingModule,
		BrowserAnimationsModule,
		BrowserModule,
		FormsModule,
		HttpClientModule,
		NGPrimeModule,
		provideFirebaseApp(() => initializeApp(firebase.Cfg)),
		provideAuth(() => {
			const auth = getAuth();
			if (isLocalhost) {
				connectAuthEmulator(auth, 'http://localhost:9099', {
					disableWarnings: true,
				});
			}
			return auth;
		}),
		provideFirestore(() => {
			let firestore: Firestore;
			if (isLocalhost) {
				firestore = initializeFirestore(getApp(), {
					experimentalForceLongPolling: true,
				});
				connectFirestoreEmulator(firestore, 'localhost', 8080);
			} else {
				firestore = getFirestore();
			}
			return firestore;
		}),
		provideFunctions(() => {
			let functions: Functions;
			if (isLocalhost) {
				functions = getFunctions();
				connectFunctionsEmulator(functions, 'localhost', 5001);
			} else {
				functions = getFunctions();
			}
			return functions;
		}),
		provideStorage(() => {
			const storage = getStorage();
			if (isLocalhost) {
				connectStorageEmulator(storage, 'localhost', 9199);
			}
			return storage;
		}),
	],
	providers: [
		{ provide: API_KEY, useValue: gapiSettings.Sheets },
		{ provide: APP_INITIALIZER, useFactory: () => () => {}, deps: [Sentry.TraceService], multi: true },
		{ provide: ErrorHandler, useValue: Sentry.createErrorHandler({ showDialog: false }) },
		{ provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: QuickbooksInterceptor, multi: true },
		{ provide: Sentry.TraceService, deps: [Router] },
		//{ provide: ErrorHandler, useClass: GlobalErrorHandler },
		AuthGuard,
		GoogleIdentityService,
		GoogleSheetsDbService,
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
