import { Injectable } from '@angular/core';
import { CollectionReference, DocumentData, Firestore, collection, doc, getDoc, getDocs, orderBy, query, setDoc, where } from '@angular/fire/firestore';
import { PiePartner } from '../models';

@Injectable({
	providedIn: 'root',
})
export class PIE_Partners_Service {
	public host: string;
	public partner!: PiePartner;
	constructor(private afs: Firestore) {
		this.host = window.location.hostname;
	}

	addPartner(payload: PiePartner) {
		const ref = doc(this.afs, 'pie-partners', payload.id);
		setDoc(ref, payload, { merge: true });
	}

	async getPartners() {
		const q = query<PiePartner, DocumentData>(collection(this.afs, 'pie-partners') as CollectionReference<PiePartner>, where('name', '!=', 'localhost'), orderBy('name'));
		return await getDocs(q);
	}

	async getPartner() {
		const q = doc(this.afs, 'pie-partners', this.host);
		this.partner = await getDoc(q).then(data => data.data() as PiePartner);
		return await getDoc(q).then(data => data.data() as PiePartner);
	}
}
