import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { Sponsor } from '../models/chat-sponsors.model';

@Injectable({
	providedIn: 'root',
})
export class SponsorsService {
	constructor(private http: HttpClient) {}
	async getSponsors(): Promise<Sponsor[]> {
		try {
			const source$ = this.http.get<any>('assets/sponsors/sponsors.json');
			const { sponsors } = await firstValueFrom(source$);

			return sponsors as Sponsor[];
		} catch (error) {
			console.error('Error fetching sponsors:', error);
			return [];
		}
	}
}
