export interface SandboxProduct {
	category: string;
	code: string;
	id: string;
	image: string;
	inventoryStatus: string;
	name: string;
	price: number;
	quantity: number;
	rating: number;
	orders: Order[];
}

export interface Order {
	amount: number;
	customer: string;
	date: string;
	id: string;
	productCode: string;
	quantity: number;
	status: string;
}
