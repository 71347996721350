import { Injectable } from '@angular/core';
import { CollectionReference, DocumentData, Firestore, addDoc, collection, deleteDoc, doc, getDocs, orderBy, query, setDoc, where } from '@angular/fire/firestore';
import { CustomFunctions } from '../models';
import { CycleEvent } from '../models/mas-cycles.model';
import { Firebase_Functions_Service } from './functions.service';

@Injectable({
	providedIn: 'root',
})
export class MAS_Events_Service {
	constructor(private afs: Firestore, private firebaseFunctions: Firebase_Functions_Service) {}

	addEvent(payload: CycleEvent): void {
		const ref = collection(this.afs, 'mas-events');
		addDoc(ref, payload);
	}

	deleteEvent(id: string): void {
		const ref = doc(this.afs, 'mas-events', id);
		deleteDoc(ref);
	}

	async getCurrentEvents(): Promise<CycleEvent[]> {
		const q = query<CycleEvent, DocumentData>(
			collection(this.afs, 'mas-events') as CollectionReference<CycleEvent>,
			where('endTime', '>', CustomFunctions.localClientTime()),
			orderBy('endTime')
		);
		return await getDocs(q).then(data =>
			data.docs.map(m => {
				const data = m.data();
				data.id = m.id;
				return data;
			})
		);
	}

	async getEventsByCycle(cycle: string): Promise<CycleEvent[]> {
		const q = query<CycleEvent, DocumentData>(collection(this.afs, 'mas-events') as CollectionReference<CycleEvent>, where('cycleName', '==', cycle));

		const qDocs = await getDocs(q);

		if (!qDocs) return [];

		return qDocs.docs.map(m => {
			const data = m.data() as any;
			data.id = m.id;
			return data;
		});
	}

	async setEventById(id: string, payload: CycleEvent) {
		const cycleEvent = doc(this.afs, 'mas-events', id);
		return setDoc(cycleEvent, payload, { merge: true });
	}
}
