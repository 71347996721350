export interface Current {
	current: {
		title: string;
		body: string;
		dateCreated: Date;
		dateDecided?: Date;
		movedBy: string;
		secondedBy: string;
		status: string;
		isActiveMotion: boolean;
		isProposedAmendment: boolean;
	};
}

export interface Amendments {
	amendments?: Current[];
}
export interface Motion extends Current, Amendments {
	id: string | '';
}

export interface DialogData {
	motion: Motion;
	mode: string;
}

export interface SecondedDialogData {
	list: any;
}

export interface Attendee {
	accountId: string;
	ispresent: boolean;
	name: string;
}
