import { doc, getDoc, setDoc } from 'firebase/firestore';
import { MAS_Schedules_Service } from '../services';
import { AnyObject, captureError } from '../utilities';
import { MASAccountModel } from './mas-accounts.model';

export interface Classes {
	accountId: string;
	displayName: string;
	programId: string;
	programName: string;
	reserveLimit: number;
	schedules: ScheduleElementType[];
	tableId: string;
}

export interface DisplaySchedule extends Schedule {
	startTime: string;
	endTime: string;
}

export interface Schedule {
	attendance?: ScheduleAttendance[];
	cycleName: string;
	end: { dateTime: string; timeZone: string };
	eventType: string;
	id: string;
	notifications?: Notifications[];
	recurringEventId?: string | null;
	start: { dateTime: string; timeZone: string };
	summary: string;
	viewSettings?: {
		attendanceCount?: number;
		buttonDisabled?: boolean;
		duplicates?: boolean;
		hasJoined?: boolean;
		memberReservedIndex?: number;
		optionReserve?: boolean;
		reservationCount?: number;
	};
}

export interface ScheduleElementType {
	attendance: ScheduleAttendance[];
	css: 'selected' | 'unselected';
	cycleName: string;
	disable: boolean;
	enrolled: boolean;
	icon: 'pi pi-times-circle' | 'pi pi-check-circle';
	id: string;
	reserveLimit: number;
	startDateTime: string;
	testingLimits: number;
}

export interface Notifications {
	email?: string;
	id: string;
	name: string;
	phone?: string;
}

export interface ScheduleAttendance {
	attendanceTotal?: number;
	attended: boolean;
	id: string;
	name: string;
	notifications?: Notifications;
	reserved: boolean;
	tournaments?: string;
}

export class MASScheduleModel {
	[x: string]: any;
	#data: Schedule;

	constructor(data: Schedule, private scheduleService: MAS_Schedules_Service) {
		this.#data = data;
	}

	// Access to raw Firestore data// ==========================
	// 📦 Public API
	// ==========================

	get raw(): Schedule {
		return this.#data;
	}

	// Common field accessors
	get id(): string {
		return this.#data.id;
	}

	clone(): MASScheduleModel {
		const clonedRaw: Schedule = JSON.parse(JSON.stringify(this.raw));
		return new MASScheduleModel(clonedRaw, this.scheduleService);
	}

	toJSON(): Schedule {
		return this.#data;
	}

	async save(): Promise<Schedule> {
		try {
			const id = this.raw.id;
			const ref = doc(this.scheduleService.afs, 'mas-schedules', id);

			const snapshot = await getDoc(ref);
			const exists = snapshot.exists();

			if (exists) {
				await setDoc(ref, this.raw, { merge: true });
			} else {
				await setDoc(ref, this.raw);
			}

			return this.raw;
		} catch (error) {
			captureError('MASScheduleModel.save', error, this.raw.id);
			throw error;
		}
	}

	// ==========================
	// 🛠️ Mutators
	// ==========================

	correctAttendanceName(account: MASAccountModel): this {
		const targetId = account.raw.id;
		const newName = account.fullName;

		if (this.raw.attendance && Array.isArray(this.raw.attendance)) {
			this.raw.attendance.forEach(att => {
				if (att.id === targetId) {
					att.name = newName;
				}
			});
		}

		return this;
	}

	correctAttendanceNotificationName(account: MASAccountModel): this {
		const targetId = account.raw.id;
		const newName = account.fullName;

		if (this.raw.attendance && Array.isArray(this.raw.attendance)) {
			this.raw.attendance.forEach(att => {
				if (att.notifications?.id === targetId) {
					att.notifications.name = newName;
				}
			});
		}

		return this;
	}

	removeAttendance(account: MASAccountModel): this {
		const targetId = account.raw.id;

		if (this.raw.attendance && Array.isArray(this.raw.attendance)) {
			this.raw.attendance = this.raw.attendance.filter(att => att.id !== targetId);
		}

		return this;
	}

	// ==========================
	// 🔍 Diff Utilities
	// ==========================

	/**
	 * Returns a deep object of differences between this model and another model.
	 */
	diffRaw(otherRaw: Schedule): AnyObject {
		return this._findDifferences(this.raw, otherRaw) || {};
	}

	diffSummaryRaw(otherRaw: Schedule): string[] {
		const changedPaths: string[] = [];
		this._findDifferencesSummary(this.raw, otherRaw, [], changedPaths);
		return changedPaths;
	}

	hasChangesRaw(otherRaw: Schedule): boolean {
		return this.diffSummaryRaw(otherRaw).length > 0;
	}

	// ==========================
	// 🔒 Private Helpers
	// ==========================

	private _findDifferences(a: any, b: any): any {
		if (typeof a !== 'object' || typeof b !== 'object' || a === null || b === null) {
			return a !== b ? a : undefined;
		}

		if (Array.isArray(a) && Array.isArray(b)) {
			return JSON.stringify(a) !== JSON.stringify(b) ? a : undefined;
		}

		const result: AnyObject = {};
		const keys = new Set([...Object.keys(a || {}), ...Object.keys(b || {})]);

		for (const key of keys) {
			const diff = this._findDifferences(a[key], b[key]);
			if (diff !== undefined) {
				result[key] = diff;
			}
		}

		return Object.keys(result).length > 0 ? result : undefined;
	}

	private _findDifferencesSummary(a: any, b: any, path: string[] = [], changedPaths: string[]) {
		if (typeof a !== 'object' || typeof b !== 'object' || a === null || b === null) {
			if (a !== b) changedPaths.push(path.join('.'));
			return;
		}

		if (Array.isArray(a) && Array.isArray(b)) {
			if (JSON.stringify(a) !== JSON.stringify(b)) changedPaths.push(path.join('.'));
			return;
		}

		const keys = new Set([...Object.keys(a || {}), ...Object.keys(b || {})]);
		for (const key of keys) {
			this._findDifferencesSummary(a[key], b[key], [...path, key], changedPaths);
		}
	}
}
