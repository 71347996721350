export class Partner {
	constructor(
		public css: {} | null = null,
		public logo: string | null = null,
		public name: string | null = null,
		public foundation: string | null = null,
		public slogan: string | null = null,
		public website: string | null = null,
		public trialOffer: string | null = null,
		public fundraiser: {
			startDate: string;
			endDate: string;
			kickoff: string;
			deadline: string;
			orientations: string[];
		} | null = null
	) {}
}

export class Contribution {
	constructor(public description: string, public dollars: number, public hours: number, public name: string, public timeIn: string, public timeOut: string, public type: string) {}
}

export interface Contact {
	addresses?: {
		city?: string;
		postalCode?: string;
		region?: string;
		streetAddress?: string;
	};
	emailAddresses?: { value?: string };
	id?: string;
	mas?: {
		accountSettings?: {
			billing?: string;
			gender?: string;
			member?: string;
			status?: string;
			birthday?: string;
			school?: string;
		};
		linkedAccounts?: any;
	};
	names: {
		familyName: string;
		givenName: string;
	};
	phoneNumbers?: { value: string };
}

export interface Champions {
	Name: string;
	Year: string;
	Title: string;
	Event: string;
}

export interface Fights {
	Year: string;
	Cleanups: number;
	Volunteers: number;
	Hours: number;
	Bags: number;
	Pounds: number;
}
