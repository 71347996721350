import { Injectable, Injector } from '@angular/core';
import { MASScheduleModel, Schedule } from '../models';
import { MAS_Schedules_Service } from '../services';

@Injectable({ providedIn: 'root' })
export class MasScheduleConverterService {
	private _schedulesService!: MAS_Schedules_Service;

	constructor(private injector: Injector) {}

	private get schedulesService(): MAS_Schedules_Service {
		if (!this._schedulesService) {
			this._schedulesService = this.injector.get(MAS_Schedules_Service);
		}
		return this._schedulesService;
	}

	fromRaw(raw: Schedule): MASScheduleModel {
		return new MASScheduleModel(raw, this.schedulesService);
	}

	toRaw(model: MASScheduleModel): Schedule {
		return model.toJSON();
	}
}
