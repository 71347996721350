import { doc, getDoc, setDoc } from '@angular/fire/firestore';
import { Account, Schedule } from '.';
import { MAS_Programs_Service } from '../services';
import { AnyObject, captureError } from '../utilities';

export interface Program extends ProgramMemberships {
	attendanceStarApproved: boolean;
	css?: string;
	dailyCost?: number;
	googleGroupId?: string;
	googleGroupMembersCount: string;
	googleSharedContactId?: string;
	groupEmail: string;
	id: string;
	name: string;
	optionReserve: boolean;
	priority: number;
	reserveLimit: number;
	maxTestingLimit: number;
	testCategory: string;
	status: 'Active' | 'Inactive';
}

export interface ProgramAndSchedules extends Program {
	schedules?: Schedule[];
}

export interface ProgramIconBar {
	default: string;
	members: string;
	schedules: string;
	update: string;
	google: string;
}

export interface ProgramMember {
	billingId: string;
	billingName: string;
	billingEmail: string;
	memberId: string;
	memberName: string;
}

export interface ProgramMemberships {
	memberships: ProgramMember[];
}

// mas-programs.model.ts
export interface Ledger {
	id?: string;
	amount: number;
	description: string;
	projected: number;
	timeCreated: string;
	balance: number;
}

export interface Lesson {
	category?: string;
	id?: string;
	instructorAccount?: Account;
	instructorId: string;
	instructorName: string;
	lessonTime: Date;
	memberId?: string;
	memberName?: string;
	timeCreated: string;
}

export interface LessonAttendance {
	attended: boolean;
	id: string;
	name: string;
	notifications?: LessonNotifications;
	reserved: boolean;
}

export interface LessonNotifications {
	email?: string;
	id: string;
	name: string;
	phone?: string;
}

export enum ProgramDisplayMode {
	None = '',
	Ledger = 'ledger',
	LedgerForm = 'ledgerForm',
	Lesson = 'lesson',
	LessonForm = 'lessonForm',
	Skills = 'skills',
}

/** Interfaces for Chart data */
export interface ChartDataset {
	borderColor: string;
	data: number[];
	fill: boolean;
	label: string;
	radius: number;
}

export interface ChartData {
	labels: string[];
	datasets: ChartDataset[];
}

export interface SkillGroup {
	label: string;
	items: { label: string; category: string }[];
}

export class MASProgramModel {
	[x: string]: any;
	#data: Program;

	constructor(data: Program, private programService: MAS_Programs_Service) {
		this.#data = data;
	}

	// Access to raw Firestore data
	get raw(): Program {
		return this.#data;
	}

	// Common field accessors
	get id(): string {
		return this.#data.id;
	} // ==========================
	// 📦 Public API
	// ==========================

	clone(): MASProgramModel {
		const clonedRaw: Program = JSON.parse(JSON.stringify(this.raw));
		return new MASProgramModel(clonedRaw, this.programService);
	}

	toJSON(): Program {
		return this.#data;
	}

	async save(): Promise<Program> {
		try {
			const id = this.raw.id;
			const ref = doc(this.programService.afs, 'mas-programs', id);

			const snapshot = await getDoc(ref);
			const exists = snapshot.exists();

			if (exists) {
				await setDoc(ref, this.raw, { merge: true });
			} else {
				await setDoc(ref, this.raw);
			}

			return this.raw;
		} catch (error) {
			captureError('MASProgramModel.save', error, this.raw.id);
			throw error;
		}
	}

	getMembers(): string[] {
		return this.raw.memberships?.map(m => m.memberName)?.filter(Boolean) || [];
	}

	// ==========================
	// 🛠️ Mutators
	// ==========================

	correctMemberName(account: Account): this {
		const fullName = `${account.names.givenName} ${account.names.familyName}`;

		if (Array.isArray(this.#data.memberships)) {
			for (const member of this.#data.memberships) {
				if (member.memberId === account.id) {
					member.memberName = fullName;
				}
			}
		}

		return this;
	}

	correctBillingName(account: Account): this {
		const fullName = `${account.names.givenName} ${account.names.familyName}`;

		if (Array.isArray(this.#data.memberships)) {
			for (const billing of this.#data.memberships) {
				if (billing.billingId === account.id) {
					billing.billingName = fullName;
				}
			}
		}

		return this;
	}

	removeMember(account: Account): this {
		if (Array.isArray(this.#data.memberships)) {
			this.#data.memberships = this.#data.memberships.filter(member => member.memberId !== account.id);
		}

		return this;
	}

	// ==========================
	// 🔍 Diff Utilities
	// ==========================

	/**
	 * Returns a deep object of differences between this model and another model.
	 */
	diffRaw(otherRaw: Program): AnyObject {
		return this._findDifferences(this.raw, otherRaw) || {};
	}

	diffSummaryRaw(otherRaw: Program): string[] {
		const changedPaths: string[] = [];
		this._findDifferencesSummary(this.raw, otherRaw, [], changedPaths);
		return changedPaths;
	}

	hasChangesRaw(otherRaw: Program): boolean {
		return this.diffSummaryRaw(otherRaw).length > 0;
	}

	// ==========================
	// 🔒 Private Helpers
	// ==========================

	private _findDifferences(a: any, b: any): any {
		if (typeof a !== 'object' || typeof b !== 'object' || a === null || b === null) {
			return a !== b ? a : undefined;
		}

		if (Array.isArray(a) && Array.isArray(b)) {
			return JSON.stringify(a) !== JSON.stringify(b) ? a : undefined;
		}

		const result: AnyObject = {};
		const keys = new Set([...Object.keys(a || {}), ...Object.keys(b || {})]);

		for (const key of keys) {
			const diff = this._findDifferences(a[key], b[key]);
			if (diff !== undefined) {
				result[key] = diff;
			}
		}

		return Object.keys(result).length > 0 ? result : undefined;
	}

	private _findDifferencesSummary(a: any, b: any, path: string[] = [], changedPaths: string[]) {
		if (typeof a !== 'object' || typeof b !== 'object' || a === null || b === null) {
			if (a !== b) changedPaths.push(path.join('.'));
			return;
		}

		if (Array.isArray(a) && Array.isArray(b)) {
			if (JSON.stringify(a) !== JSON.stringify(b)) changedPaths.push(path.join('.'));
			return;
		}

		const keys = new Set([...Object.keys(a || {}), ...Object.keys(b || {})]);
		for (const key of keys) {
			this._findDifferencesSummary(a[key], b[key], [...path, key], changedPaths);
		}
	}
}
