export interface Broadcast {
	id?: string;
	message: string;
	phone?: string;
	sponsor?: string;
	sponsorMessage?: string;
	status: 'Open' | 'Closed';
	subjectDescription: string;
	subjectId: string;
	timeStamp: string;
	url?: string;
}
