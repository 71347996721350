import { Injectable } from '@angular/core';
import { addDoc, collection, CollectionReference, collectionSnapshots, deleteDoc, doc, DocumentData, Firestore, getDocs, orderBy, query, setDoc, where } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CustomFunctions } from '../models';
import { Cycle } from '../models/mas-cycles.model';

@Injectable({
	providedIn: 'root',
})
export class MAS_Cycles_Service {
	constructor(private afs: Firestore) {}

	addCycle(payload: Cycle) {
		const ref = collection(this.afs, 'mas-cycles');
		addDoc(ref, payload);
	}

	deleteCycle(id: string) {
		const ref = doc(this.afs, 'mas-cycles', id);
		deleteDoc(ref);
	}

	getCyclesAsObservable(): Observable<Cycle[]> {
		return collectionSnapshots<Cycle>(query<Cycle, DocumentData>(collection(this.afs, 'mas-cycles') as CollectionReference<Cycle>, where('status', '==', 'Active'), orderBy('startDate'))).pipe(
			map(changes => {
				return changes.map(a => {
					const data = a.data();
					data.id = a.id;
					return data;
				});
			})
		);
	}

	async getCurrentCyclesAsPromise(): Promise<Cycle[]> {
		const d = new Date();
		const today = CustomFunctions.dateToDateString(d);
		const q = query<Cycle, DocumentData>(collection(this.afs, 'mas-cycles') as CollectionReference<Cycle>, where('endDate', '>=', today));
		return await getDocs(q).then(data => data.docs.map(m => m.data()));
	}

	async getInactiveCyclesAsPromise(): Promise<Cycle[]> {
		const q = query<Cycle, DocumentData>(collection(this.afs, 'mas-cycles') as CollectionReference<Cycle>, where('status', '==', 'Inactive'), orderBy('startDate'));
		return await getDocs(q).then(data => data.docs.map(m => m.data()));
	}

	async getAllCyclesAsPromise(): Promise<Cycle[]> {
		const q = query<Cycle, DocumentData>(collection(this.afs, 'mas-cycles') as CollectionReference<Cycle>);
		return await getDocs(q).then(data => data.docs.map(m => m.data()));
	}

	setCycle(id: string, payload: Cycle) {
		const ref = doc(this.afs, 'mas-cycles', id);
		setDoc(ref, payload, { merge: true });
	}
}
