import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { GoogleIdentityService } from '../services/identity.service';

@Injectable()
export class AuthGuard implements CanActivate {
	constructor(private identityService: GoogleIdentityService, private router: Router) {}
	canActivate(): Observable<boolean> | Promise<boolean> | boolean {
		if (this.identityService.getUser === null) {
			this.router.navigate(['sign-in']);
		}
		return true;
	}
}
