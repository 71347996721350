import { Injectable } from '@angular/core';
import {
	CollectionReference,
	DocumentData,
	DocumentReference,
	Firestore,
	Query,
	collection,
	collectionSnapshots,
	deleteDoc,
	doc,
	getDoc,
	getDocs,
	limit,
	orderBy,
	query,
	setDoc,
	where,
} from '@angular/fire/firestore';
import * as Sentry from '@sentry/angular';
import { map } from 'rxjs/operators';
import { MAS_Cycles_Service } from '.';
import { MasScheduleConverterService } from '../converters/mas-schedules.converter';
import { Account, DisplaySchedule, MASScheduleModel, Notifications, Schedule } from '../models';
import { DateTimeUtils } from '../utilities';

@Injectable({
	providedIn: 'root',
})
export class MAS_Schedules_Service {
	public selectedNode!: Account;

	constructor(public afs: Firestore, private cycles_Service: MAS_Cycles_Service, private scheduleConverter: MasScheduleConverterService) {}

	async clearSchedules(program: string, testCategory: string | undefined, calendar: Schedule[]) {
		const log: { styleColor: string; msg: string }[] = [];
		const givenDate = DateTimeUtils.formatDate(new Date(), 'midnight');

		// If no program is provided, return immediately
		if (!program) return log;

		// Build the search array
		const searchArray = testCategory ? [program, testCategory] : [program];

		// Create a set of schedule IDs from the Google calendar
		const calendarIds = new Set(calendar.map(m => m.id));

		// Get schedules for program after midnight using an "in" filter on summary
		const q = query<Schedule, DocumentData>(collection(this.afs, 'mas-schedules') as CollectionReference<Schedule>, where('start.dateTime', '>', givenDate), where('summary', 'in', searchArray));

		const snap = await getDocs(q);
		const schedules = snap.docs.map(m => m.data());

		// Process schedules, separating those to remove and those to keep
		schedules.forEach(each => {
			if (!calendarIds.has(each.id)) {
				if (!each.attendance || each.attendance.length === 0) {
					this.deleteSchedule(each.id);
					log.push({
						styleColor: 'orange',
						msg: `Removed ${each.summary}, ${each.start.dateTime} - ${each.end.dateTime}`,
					});
				} else {
					log.push({
						styleColor: 'red',
						msg: `Schedule does not exist in Google Calendar but could not be removed from MAS because it has reservations: ${each.summary}, ${each.start.dateTime} - ${each.end.dateTime}`,
					});
				}
			}
		});

		return log;
	}

	async deleteSchedule(id: string): Promise<void> {
		const ref = doc(this.afs, 'mas-schedules', id);
		return deleteDoc(ref);
	}

	async getScheduleById(id: string): Promise<Schedule> {
		const docRef = doc(this.afs, 'mas-schedules', id) as DocumentReference<Schedule>;
		return (await getDoc(docRef)).data()!;
	}

	async getSchedulesByProgramAndDateRange(programs: string[], startDate: string, endDate: string) {
		if (programs.length === 0) return [];

		const q = query<Schedule, DocumentData>(
			collection(this.afs, 'mas-schedules') as CollectionReference<Schedule>,
			where('summary', 'in', programs),
			where('start.dateTime', '>', startDate),
			where('start.dateTime', '<', endDate),
			orderBy('start.dateTime')
		);

		return (await getDocs(q)).docs.map(m => m.data());
	}

	async getSchedulesNotClosed(endDate: string, programs: string[]) {
		if (programs.length === 0) return [];
		const q = query<Schedule, DocumentData>(
			collection(this.afs, 'mas-schedules') as CollectionReference<Schedule>,
			where('eventType', '==', 'default'),
			where('summary', 'in', programs),
			where('end.dateTime', '<', endDate)
		);

		const qDocs = await getDocs(q);
		if (!qDocs) return [];
		return qDocs.docs.map(m => m.data());
	}

	async getSchedulesByProgramAsPromise(programName: string) {
		const q = query(collection(this.afs, 'mas-schedules'), where('summary', '==', programName), where('end.dateTime', '>', DateTimeUtils.formatDate(new Date(), 'local')));
		const qDocs = await getDocs(q);
		const qData = qDocs.docs.map(m => m.data());

		const schedules = qData.map(m => {
			m['startDateTime'] = DateTimeUtils.formatDate(m['start'].dateTime as unknown as Date, 'friendly', 0);
			return m;
		});

		return schedules;
	}

	getSchedulesByCycleAndProgram(cycle: string, program: string) {
		return collectionSnapshots<Schedule>(
			query<Schedule, DocumentData>(
				collection(this.afs, 'mas-schedules') as CollectionReference<Schedule>,
				where('cycleName', '==', cycle),
				where('summary', '==', program),
				orderBy('start.dateTime')
			)
		).pipe(
			map(changes => {
				return changes.map(a => {
					const data = a.data();
					data.id = a.id;
					return data;
				});
			})
		);
	}

	async getSchedulesAsPromise(ignorePast: boolean = false): Promise<Schedule[]> {
		const collectionRef = collection(this.afs, 'mas-schedules') as CollectionReference<Schedule>;
		const q: Query<Schedule> = ignorePast
			? query<Schedule, DocumentData>(collectionRef, where('end.dateTime', '>', DateTimeUtils.formatDate(new Date(), 'local')))
			: query<Schedule, DocumentData>(collectionRef);
		const querySnapshot = await getDocs(q);
		return querySnapshot.docs.map(doc => doc.data());
	}

	async getSchedulesWithMembersAttendedAsPromise(id: string) {
		const q = query<Schedule, DocumentData>(collection(this.afs, 'mas-schedules') as CollectionReference<Schedule>, where('id', '>', id));
		return await getDocs(q).then(data => data.docs.map(m => m.data()));
	}

	getSchedulesByDateRangeLimit3(startDate: string, endDate: string) {
		return collectionSnapshots<DisplaySchedule>(
			query<DisplaySchedule, DocumentData>(
				collection(this.afs, 'mas-schedules') as CollectionReference<DisplaySchedule>,
				where('end.dateTime', '>', startDate),
				where('end.dateTime', '<', endDate),
				orderBy('end.dateTime'),
				limit(3)
			)
		).pipe(
			map(changes =>
				changes.map(a => {
					const data = a.data();
					data.startTime = DateTimeUtils.formatDate(data.start.dateTime, 'time12');
					data.endTime = DateTimeUtils.formatDate(data.end.dateTime, 'time12');
					data.id = a.id;
					return data;
				})
			)
		);
	}

	getSchedulesByDateRangeLimit1(startDate: string, endDate: string) {
		return collectionSnapshots<DisplaySchedule>(
			query<DisplaySchedule, DocumentData>(
				collection(this.afs, 'mas-schedules') as CollectionReference<DisplaySchedule>,
				where('end.dateTime', '>', startDate),
				where('end.dateTime', '<', endDate),
				where('summary', '==', 'YAS'),
				orderBy('end.dateTime'),
				limit(1)
			)
		).pipe(
			map(changes => {
				return changes.map(a => {
					const data = a.data();
					data.startTime = DateTimeUtils.formatDate(data.start.dateTime, 'time12');
					data.endTime = DateTimeUtils.formatDate(data.end.dateTime, 'time12');
					data.id = a.id;
					return data;
				});
			})
		);
	}

	getSchedulesByProgramAsObservable(program: string) {
		const d = new Date();
		const givenDate = DateTimeUtils.formatDate(d, 'date');

		return collectionSnapshots<Schedule>(
			query<Schedule, DocumentData>(
				collection(this.afs, 'mas-schedules') as CollectionReference<Schedule>,
				where('summary', '==', program),
				where('start.dateTime', '>=', givenDate),
				orderBy('start.dateTime')
			)
		).pipe(
			map(changes => {
				return changes.map(a => {
					const data = a.data();
					data.id = a.id;
					return data;
				});
			})
		);
	}

	async getSchedulesByProgramForChart(program: string) {
		const d = new Date();
		const givenDate = DateTimeUtils.formatDate(d, 'date');

		const q = query<Schedule, DocumentData>(
			collection(this.afs, 'mas-schedules') as CollectionReference<Schedule>,
			where('summary', '==', program),
			where('start.dateTime', '<', givenDate),
			where('start.dateTime', '>', '2022'),
			orderBy('start.dateTime')
		);
		const qDocs = await getDocs(q);
		if (!qDocs) return [];
		return qDocs.docs.map(m => m.data());
	}

	getSchedulesByReference(fld: string, operator: any, condition: any) {
		return collectionSnapshots<any>(query<any, DocumentData>(collection(this.afs, 'mas-schedules') as CollectionReference<any>, where(fld, operator, condition))).pipe(
			map(changes => {
				return changes.map(c => {
					const data = c.data() as any;
					data.id = c.id;
					return data;
				});
			})
		);
	}

	async getSchedulesByTestCategories(testCategories: string[]) {
		if (testCategories.length === 0) return [];

		const q = query<Schedule, DocumentData>(
			collection(this.afs, 'mas-schedules') as CollectionReference<Schedule>,
			where('summary', 'in', testCategories),
			where('end.dateTime', '>', DateTimeUtils.formatDate(new Date(), 'local'))
		);

		const qDocs = await getDocs(q);
		return qDocs.docs.map(m => {
			const data = m.data();
			return {
				...data,
				startDateTime: DateTimeUtils.formatDate(data.start.dateTime, 'friendly'),
			} as Schedule;
		});
	}

	getSchedulesForTesting(cycle: string, programs: string[]) {
		if (programs.length === 0) return [];
		const q = query<Schedule, DocumentData>(collection(this.afs, 'mas-schedules') as CollectionReference<Schedule>, where('cycleName', '==', cycle), where('summary', 'in', programs));
		return getDocs(q);
	}

	async getSchedulesForYAS() {
		const q = query<Schedule, DocumentData>(collection(this.afs, 'mas-schedules') as CollectionReference<Schedule>, where('summary', '==', 'YAS'), where('end.dateTime', '>', '2023-06-01'));
		return await getDocs(q).then(document => document.docs.map(m => m.data()));
	}

	async getSchedulesToUpdate(program: string) {
		const d = new Date();
		const givenDate = DateTimeUtils.formatDate(d, 'date');

		const q = query<Schedule, DocumentData>(collection(this.afs, 'mas-schedules') as CollectionReference<Schedule>, where('summary', '==', program), where('start.dateTime', '>=', givenDate));
		const qDocs = await getDocs(q);
		if (!qDocs) return [];
		return qDocs.docs.map(m => m.data());
	}

	async getSchedulesInProgramsForUpdate(programs: string[]): Promise<MASScheduleModel[]> {
		const d = new Date();
		const givenDate = DateTimeUtils.formatDate(d, 'date');

		const q = query<Schedule, DocumentData>(collection(this.afs, 'mas-schedules') as CollectionReference<Schedule>, where('programId', 'in', programs), where('start.dateTime', '>=', givenDate));

		const qDocs = await getDocs(q);
		if (!qDocs) return [];

		return qDocs.docs.map(doc => this.scheduleConverter.fromRaw(doc.data()));
	}
	getSchedulesToUpdateCycles() {
		this.getSchedulesAsPromise().then(schedules => {
			let results: any[] = [];
			schedules.forEach(schedule => {
				results.push(schedule);
			});
			this.updateSchedulesCycle(results);
		});
	}

	async setScheduleById(id: string, payload: Schedule, merge: boolean = true) {
		const schedule = doc(this.afs, 'mas-schedules', id);
		return await setDoc(schedule, payload, { merge: merge });
	}

	async findExistingSchedule(each: Schedule): Promise<boolean> {
		const q = query<Schedule, DocumentData>(
			collection(this.afs, 'mas-schedules') as CollectionReference<Schedule>,
			where('summary', '==', each.summary),
			where('start.dateTime', '==', each.start.dateTime),
			where('end.dateTime', '==', each.end.dateTime)
		);
		const count = await getDocs(q).then(docs => docs.size);
		return count > 0 ? true : false;
	}

	async updateSchedulesCycle(schedules: any[]) {
		const cycles = await this.cycles_Service.getCurrentCyclesAsPromise();

		schedules.forEach(schedule => {
			const shortStartDateAsString = DateTimeUtils.formatDate(schedule.start.dateTime, 'date');

			const matchedCycle = cycles.find(cycle => shortStartDateAsString >= cycle.startDate && shortStartDateAsString <= cycle.endDate);

			if (matchedCycle) {
				schedule.cycleName = matchedCycle.cycleName;
				this.setScheduleById(schedule.id, schedule);
			}
		});
	}

	async getSchedulesByPropertiesAsPromise(searchArray: Notifications[]): Promise<Schedule[]> {
		try {
			if (searchArray.length === 0) return [];

			if (window.location.hostname == 'localhost') console.log('getSchedulesByPropertiesAsPromise=>searchArray', searchArray);

			const q = query<Schedule, DocumentData>(collection(this.afs, 'mas-schedules') as CollectionReference<Schedule>, where('notifications', 'array-contains-any', searchArray));

			const querySnapshot = await getDocs(q);
			return querySnapshot.docs.map(m => m.data());
		} catch (error) {
			// Log error to console
			console.error('Error in getSchedulesByPropertiesAsPromise:', error);

			// Capture the error in Sentry with additional context
			Sentry.withScope(scope => {
				scope.setTag('function', 'getSchedulesByPropertiesAsPromise');
				scope.setExtra('searchArray', searchArray);
				Sentry.captureException(error);
			});

			// Explicitly throw the error to allow the caller to handle it
			throw new Error(`Failed to fetch schedules: ${error instanceof Error ? error.message : JSON.stringify(error)}`);
		}
	}

	async getSchedulesWithNotifications() {
		const today = new Date();
		today.setHours(today.getHours() - 4);
		const tomorrow = new Date();
		tomorrow.setDate(tomorrow.getDate() + 2);
		const q = query<Schedule, DocumentData>(
			collection(this.afs, 'mas-schedules') as CollectionReference<Schedule>,
			where('start.dateTime', '>', today.toISOString().slice(0, 10)),
			where('start.dateTime', '<', tomorrow.toISOString().slice(0, 10))
		);
		return (await getDocs(q)).docs.map(m => m.data());
	}
}
