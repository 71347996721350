import { Injectable, signal } from '@angular/core';
import {
	addDoc,
	collection,
	CollectionReference,
	collectionSnapshots,
	deleteDoc,
	doc,
	docData,
	DocumentData,
	DocumentReference,
	Firestore,
	getDoc,
	getDocs,
	limit,
	orderBy,
	query,
	setDoc,
	updateDoc,
	where,
} from '@angular/fire/firestore';
import * as Sentry from '@sentry/angular';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { MAS_Schedules_Service } from '.';
import { MasProgramConverterService } from '../converters/mas-programs.converter';
import { CustomFunctions, Ledger, Lesson, MASProgramModel, Program, ProgramAndSchedules, ProgramMember, SkillGroup } from '../models';
import { ScheduleProcessor } from '../utilities';

@Injectable({
	providedIn: 'root',
})
export class MAS_Programs_Service {
	public sharedData = signal<{ name: string; age: number }>({ name: 'John Doe', age: 30 });
	private _isLegacy$ = new BehaviorSubject<boolean>(false);
	public isLegacy$ = this._isLegacy$.asObservable();

	constructor(public afs: Firestore, private schedule_Service: MAS_Schedules_Service, private programConverter: MasProgramConverterService) {}

	getSharedData(value: { name: string; age: number }) {
		this.sharedData.set(value);
	}

	async getLegacyLessons(): Promise<Lesson[]> {
		try {
			const q = query<Lesson, DocumentData>(collection(this.afs, 'mas-lessons') as CollectionReference<Lesson>);
			const qDocs = await getDocs(q);
			if (!qDocs) return [];
			return qDocs.docs.map(m => this.convertLessonDatesFromFirestore(m.data()));
		} catch (error) {
			Sentry.captureException(error);
			throw error;
		}
	}

	async checkYASMembership(id: string) {
		try {
			const q = query(collection(this.afs, 'mas-programs'), where('name', '==', 'YAS'));
			const qDocs = await getDocs(q);
			if (!qDocs) return false;
			const yasProgram = <Program>qDocs.docs.pop()?.data();
			if (!yasProgram) return false;
			const yasMembership = yasProgram.memberships?.map(m => m.memberId);
			return yasMembership?.includes(id);
		} catch (error) {
			Sentry.captureException(error);
			throw error;
		}
	}

	async checkIfLegacy(memberships: string[]): Promise<void> {
		try {
			const programs = await this.getProgramsByMemberships(memberships);
			const isLegacy = programs.some(program => program.name === 'Legacy');
			this._isLegacy$.next(isLegacy);
		} catch (error) {
			Sentry.captureException(error);
			throw error;
		}
	}

	async getProgramById(programId: string): Promise<Program | undefined> {
		try {
			const docRef = doc(this.afs, 'mas-programs', programId);
			const qDoc = await getDoc(docRef);
			if (!qDoc) return;
			return qDoc.data() as Program;
		} catch (error) {
			Sentry.captureException(error);
			throw error;
		}
	}

	async getProgramByName(programName: string): Promise<Program | undefined> {
		try {
			const q = query<Program, DocumentData>(collection(this.afs, 'mas-programs') as CollectionReference<Program>, where('name', '==', programName));
			const qDocs = await getDocs(q);
			if (!qDocs) return;
			return qDocs.docs.map(m => m.data()).pop() as Program;
		} catch (error) {
			Sentry.captureException(error);
			throw error;
		}
	}

	getProgramsAsObservable(): Observable<Program[]> {
		return collectionSnapshots<Program>(
			query<Program, DocumentData>(collection(this.afs, 'mas-programs') as CollectionReference<Program>, where('status', '==', 'Active'), orderBy('status'), orderBy('name'))
		).pipe(
			map(changes => {
				return changes.map(a => {
					const data = a.data();
					data.id = a.id;
					data.css = 'unselected';
					return data;
				});
			})
		);
	}

	async getProgramsAsPromise(): Promise<Program[]> {
		try {
			const q = query<Program, DocumentData>(collection(this.afs, 'mas-programs') as CollectionReference<Program>);
			const qDocs = await getDocs(q);
			if (!qDocs) return [];
			return qDocs.docs.map(m => m.data());
		} catch (error) {
			Sentry.captureException(error);
			throw error;
		}
	}

	/**
	 * ! @deprecated Use newProperty instead.
	 */
	async getProgramsByMemberships(memberships: string[]): Promise<Program[]> {
		try {
			if (!memberships || memberships.length === 0) {
				return [];
			}
			const programsCollection = collection(this.afs, 'mas-programs') as CollectionReference<Program>;
			const q = query<Program, DocumentData>(programsCollection, where('id', 'in', memberships), orderBy('name'));
			const qDocs = await getDocs(q);
			return qDocs.docs.map(doc => doc.data());
		} catch (error) {
			Sentry.captureException(error);
			throw error;
		}
	}

	async getProgramModelsByMemberships(memberships: string[]): Promise<MASProgramModel[]> {
		try {
			if (!memberships || memberships.length === 0) {
				return [];
			}

			const programsCollection = collection(this.afs, 'mas-programs') as CollectionReference<Program>;
			const q = query<Program, DocumentData>(programsCollection, where('id', 'in', memberships), orderBy('name'));
			const qDocs = await getDocs(q);

			return qDocs.docs.map(doc => this.programConverter.fromRaw(doc.data()));
		} catch (error) {
			Sentry.captureException(error);
			throw error;
		}
	}
	async getTestCategory(memberships: string[]): Promise<any> {
		try {
			if (!memberships || memberships.length === 0) {
				return [];
			}
			const q = query<Program, DocumentData>(collection(this.afs, 'mas-programs') as CollectionReference<Program>, where('id', 'in', memberships), orderBy('priority', 'desc'), limit(1));
			const qDocs = await getDocs(q);
			if (!qDocs) return [];
			return qDocs.docs.map(m => m.data().testCategory);
		} catch (error) {
			Sentry.captureException(error);
			throw error;
		}
	}

	async getProgramsWithReservations(): Promise<Program[]> {
		try {
			const q = query<Program, DocumentData>(collection(this.afs, 'mas-programs') as CollectionReference<Program>, where('optionReserve', '==', true), orderBy('name'));
			const qDocs = await getDocs(q);
			if (!qDocs) return [];
			return qDocs.docs.map(m => m.data());
		} catch (error) {
			Sentry.captureException(error);
			throw error;
		}
	}

	async getProgramsAndSchedules(programsList: string[]): Promise<ProgramAndSchedules[]> {
		try {
			if (!programsList || programsList.length === 0) return [];
			const q = query<Program, DocumentData>(
				collection(this.afs, 'mas-programs') as CollectionReference<Program>,
				where('id', 'in', programsList),
				where('optionReserve', '==', true),
				orderBy('name')
			);
			const qDocs = await getDocs(q);
			if (!qDocs) return [];
			const qData = qDocs.docs.map(m => m.data() as any);
			const programs = qData.map(async m => {
				const schedules = await this.schedule_Service.getSchedulesByProgramAsPromise(m.name);
				m.schedules = schedules;
				return m;
			});
			return (await Promise.all(programs)) ?? ([] as ProgramAndSchedules[]);
		} catch (error) {
			Sentry.captureException(error);
			throw error;
		}
	}

	async getProgramsAndSchedulesNew(programsList: string[]): Promise<any[]> {
		try {
			if (!programsList || programsList.length === 0) return [];
			const q = query<Program, DocumentData>(
				collection(this.afs, 'mas-programs') as CollectionReference<Program>,
				where('id', 'in', programsList),
				where('optionReserve', '==', true),
				orderBy('name')
			);
			const qDocs = await getDocs(q);
			if (!qDocs) return [];
			const programsData: Program[] = qDocs.docs.map(doc => doc.data() as Program);
			const programsWithSchedules = programsData.map(async (program): Promise<any> => {
				const schedules = await this.schedule_Service.getSchedulesByProgramAsPromise(program.name);
				return { ...program, schedules };
			});
			return Promise.all(programsWithSchedules);
		} catch (error) {
			Sentry.captureException(error);
			throw error;
		}
	}

	async getProgramsByMembershipProperties(properties: ProgramMember): Promise<Program[]> {
		try {
			const q = query<Program, DocumentData>(collection(this.afs, 'mas-programs') as CollectionReference<Program>, where('memberships', 'array-contains', properties));
			const qDocs = await getDocs(q);
			if (!qDocs) return [];
			return qDocs.docs.map(m => m.data());
		} catch (error) {
			Sentry.captureException(error);
			throw error;
		}
	}

	async getProgramsWithTestCategory(): Promise<Program[]> {
		try {
			const q = query<Program, DocumentData>(collection(this.afs, 'mas-programs') as CollectionReference<Program>);
			const qDocs = await getDocs(q);
			if (!qDocs) return [];
			const docs = qDocs.docs.map(m => m.data());
			const fdocs = docs.filter(f => f.testCategory !== undefined);
			return fdocs;
		} catch (error) {
			Sentry.captureException(error);
			throw error;
		}
	}

	async getProgramByTestCategory(category: string): Promise<Program | undefined> {
		try {
			const q = query<Program, DocumentData>(collection(this.afs, 'mas-programs') as CollectionReference<Program>, where('testCategory', '==', category));
			const qDocs = await getDocs(q);
			if (!qDocs) return;
			return qDocs.docs.map(m => m.data()).pop() as Program;
		} catch (error) {
			Sentry.captureException(error);
			throw error;
		}
	}

	async setProgram(id: string, payload: any) {
		try {
			if (payload.css) delete payload.css;
			const ref = doc(this.afs, 'mas-programs', id);
			await setDoc(ref, payload, { merge: true });
		} catch (error) {
			Sentry.captureException(error);
			throw error;
		}
	}

	async getLegacyLedgerAsPromise(): Promise<Ledger[]> {
		try {
			const q = query<Ledger, DocumentData>(collection(this.afs, 'mas-ledger') as CollectionReference<Ledger>, orderBy('timeCreated'));
			const qDocs = await getDocs(q);
			if (!qDocs) return [];
			return qDocs.docs.map(m => {
				const data = m.data();
				data.id = m.id;
				return data;
			});
		} catch (error) {
			Sentry.captureException(error);
			throw error;
		}
	}

	getLegacyLedgerAsObservable(): Observable<Ledger[]> {
		return collectionSnapshots<Ledger>(query<Ledger, DocumentData>(collection(this.afs, 'mas-ledger') as CollectionReference<Ledger>, orderBy('timeCreated'))).pipe(
			map(changes => {
				return changes.map(a => {
					const data = a.data();
					data.id = a.id;
					return data;
				});
			})
		);
	}

	async getLegacyBalance(): Promise<Ledger | undefined> {
		try {
			const q = query<Ledger, DocumentData>(
				collection(this.afs, 'mas-ledger') as CollectionReference<Ledger>,
				where('description', '==', 'Balance'),
				orderBy('timeCreated', 'desc'),
				limit(1)
			);
			const qDocs = await getDocs(q);
			if (!qDocs) return;
			return qDocs.docs.map(m => m.data()).pop();
		} catch (error) {
			Sentry.captureException(error);
			throw error;
		}
	}

	setLedger(id: string, payload: Ledger) {
		try {
			const ref = doc(this.afs, 'mas-ledger', id);
			setDoc(ref, payload, { merge: true });
		} catch (error) {
			Sentry.captureException(error);
			throw error;
		}
	}

	async createLedger(payload: Ledger): Promise<DocumentReference<DocumentData>> {
		try {
			const ref = collection(this.afs, 'mas-ledger');
			return await addDoc(ref, payload).then(doc => doc);
		} catch (error) {
			Sentry.captureException(error);
			throw error;
		}
	}

	async deleteLedger(id: string) {
		try {
			const ref = doc(this.afs, 'mas-ledger', id);
			return await deleteDoc(ref).then(doc => doc);
		} catch (error) {
			Sentry.captureException(error);
			throw error;
		}
	}

	async createLesson(payload: Lesson): Promise<DocumentReference<DocumentData>> {
		try {
			const ref = collection(this.afs, 'mas-lessons');
			return await addDoc(ref, payload).then(doc => doc);
		} catch (error) {
			Sentry.captureException(error);
			throw error;
		}
	}

	async deleteLesson(id: string) {
		try {
			const ref = doc(this.afs, 'mas-lessons', id);
			return await deleteDoc(ref).then(doc => doc);
		} catch (error) {
			Sentry.captureException(error);
			throw error;
		}
	}

	getSkills(): Observable<SkillGroup[]> {
		const docRef = doc(this.afs, 'mas-parameters', 'lists');
		return docData(docRef).pipe(
			map(data => {
				if (data && data['skills']) {
					return data['skills'] as SkillGroup[];
				}
				return [];
			})
		);
	}

	async updateSkills(skills: SkillGroup[]): Promise<void> {
		try {
			const docRef = doc(this.afs, 'mas-parameters', 'lists');
			const docSnap = await getDoc(docRef);
			if (!docSnap.exists()) {
				await setDoc(docRef, { skills });
			} else {
				await updateDoc(docRef, { skills });
			}
		} catch (error) {
			Sentry.captureException(error);
			throw error;
		}
	}

	private convertLessonDatesFromFirestore(lesson: Lesson): Lesson {
		if (lesson.lessonTime && typeof lesson.lessonTime === 'string') {
			const dt = new Date(lesson.lessonTime);
			if (!isNaN(dt.getTime())) {
				lesson.lessonTime = dt;
			}
		}
		return lesson;
	}

	getLegacyLessonsAsObservable(): Observable<Lesson[]> {
		return collectionSnapshots<Lesson>(query<Lesson, DocumentData>(collection(this.afs, 'mas-lessons') as CollectionReference<Lesson>)).pipe(
			map(changes => {
				return changes.map(a => {
					const data = a.data();
					data.id = a.id;
					return this.convertLessonDatesFromFirestore(data);
				});
			})
		);
	}

	async setLesson(id: string, payload: Partial<Lesson>) {
		try {
			const ref = doc(this.afs, 'mas-lessons', id);
			if (payload.lessonTime instanceof Date) {
				payload.lessonTime = CustomFunctions.dateToUTCString(payload.lessonTime) as any;
			}
			await setDoc(ref, payload, { merge: false });
		} catch (error) {
			Sentry.captureException(error);
			throw error;
		}
	}

	async updateScheduleWithProgramId(schedules: any[]): Promise<any[]> {
		const programs = await this.getProgramsAsPromise();
		return new ScheduleProcessor(schedules).addProgramIds(programs).getEvents();
	}
}
