<p-toolbar [style]="{'background': '#ffc107', 'border': 'transparent', 'width':'100%', 'margin': 'auto'}">
    <div class="p-toolbar-group-left">
        <div id="logo">Martial Arts Solutions</div>
        <button class="p-col-1" type="button" pButton label="Accounts" (click)="navigateByButton('/accounts')"></button>
        <button class="p-col-1" type="button" pButton label="Programs" (click)="navigateByButton('/programs')"></button>
        <button class="p-col-1" type="button" pButton label="Cycles" (click)="navigateByButton('/cycles')"></button>
        <button class="p-col-1" type="button" pButton label="Triva" (click)="navigateByButton('/trivia')"></button>
        <button class="p-col-1" type="button" pButton label="Pie" (click)="navigateByButton('/pie')"></button>
        <button class="p-col-1" type="button" pButton label="Utilities" (click)="navigateByButton('/utilities')"></button>
    </div>
    <div class="p-toolbar-group-right">
        <button class="p-col-1" type="button" pButton label="Logout" (click)="onLogoutClick()"> </button>
    </div>
</p-toolbar>