export const quickbooks = {
	client_id: 'AB1T6X7HOJCspUx1VIA6wz9ZWxRqNP7fufJTxNRCfKVZMK0rmP',
	client_secret: 'apFOJ8f17KJfOgnR7W4IK93vxXVnQziTPcAXXPl2',
	company: '123145707500802',
	scope: 'com.intuit.quickbooks.accounting',
	response_type: 'code',
	state: 'development',
	redirect_uri: 'https://us-central1-mas-development-53ac7.cloudfunctions.net',
	emulatorEndpoint: 'http://localhost:5001/mas-development-53ac7/us-central1',
	auth_url: 'https://appcenter.intuit.com/connect/oauth2',
	token_url: 'https://oauth.platform.intuit.com/oauth2/v1/tokens/bearer',
	end_point: 'sandbox-quickbooks.api.intuit.com',
};
