import { Injectable } from '@angular/core';
import { CollectionReference, DocumentData, Firestore, addDoc, collection, collectionSnapshots, doc, getDocs, limit, orderBy, query, setDoc, where } from '@angular/fire/firestore';
import { map } from 'rxjs';
import { Broadcast } from '../models/chat-broadcast.model';
import { DateTimeUtils } from '../utilities';

@Injectable({
	providedIn: 'root',
})
export class BroadcastService {
	constructor(private afs: Firestore) {}

	async addBroadcast(broadcast: Broadcast) {
		const ref = collection(this.afs, 'chat-broadcasts');
		addDoc(ref, broadcast);
	}

	async setBroadcast(id: string, payload: Broadcast) {
		const ref = doc(this.afs, 'chat-broadcasts', id);
		await setDoc(ref, payload, { merge: true });
	}

	async addTwilio(broadcast: Broadcast, phone: string) {
		const payload = {
			to: phone,
			body: `${broadcast.subjectDescription} - ${broadcast.message}\n\nThe Jonquil City Championship appreciates our sponsor ${broadcast.sponsor}\n\n${broadcast.sponsorMessage}\n\nhttps://${broadcast.url}\n\nPhone: ${broadcast.phone}`,
		};

		const ref = collection(this.afs, 'chat-twilio');
		addDoc(ref, payload);
	}
	getBroadcasts() {
		return collectionSnapshots<Broadcast>(
			query<Broadcast, DocumentData>(
				collection(this.afs, 'chat-broadcasts') as CollectionReference<Broadcast>,
				where('status', '!=', 'Closed'),
				orderBy('status'),
				orderBy('timeStamp', 'desc'),
				limit(5)
			)
		).pipe(
			map(changes => {
				return changes.map(m => {
					const data = m.data();
					return `${DateTimeUtils.formatDate(data.timeStamp, 'time12', 0)}: ${data.subjectDescription} - ${data.message}`;
				});
			})
		);
	}

	async getOpenBroadcast() {
		const q = query<Broadcast, DocumentData>(collection(this.afs, 'chat-broadcasts') as CollectionReference<Broadcast>);

		const querySnapshot = await getDocs(q);

		const data = querySnapshot.docs.map(m => {
			const docData = m.data();
			docData['id'] = m.id;
			return docData;
		});

		const filteredData = data.filter(doc => !doc.hasOwnProperty('status') || doc.status !== 'Closed');

		return filteredData;
	}
}
