import { Account } from '.';

export interface Question {
	answer: string;
	highlight?: string;
	question: string;
	type: string;
}
export interface QuestionsWithChoices extends Question {
	answers?: string[];
}
export interface TrivaQuestionData {
	mas: {
		triviaQuestionData: { cumulativeCorrect: number; lastCorrectDate: string; lastDate: string };
	};
}
export interface TrivaQuestionHistoryData {
	givenAnswer: string;
	correctAnswer: string;
	correct: boolean;
	cumulativeCorrect: number;
	lastCorrectDate: string;
	lastDate: string;
	question: string | null | undefined;
}

export interface KioskAccount extends Account, TrivaQuestionHistoryData {}

export interface AccountTriviaData {
	givenName: string;
	familyName: string;
	cumulativeCorrect: number;
	lastCorrectDate: string;
	lastDate: string;
}
