/***********************************************************************************************************************
 * Cycles Models
 ***********************************************************************************************************************/
export interface Cycle {
	id?: string;
	cycleName: string;
	endDate: string;
	startDate: string;
	status: 'Active' | 'Inactive';
}

export interface CycleEditMode {
	editState: boolean;
}

export interface CycleEventEditMode {
	editState: boolean;
}

export interface CycleEvent {
	buttonText: string;
	description: string;
	title: string;
	cycleName: string;
	endTime: string;
	id: string;
	image: string;
	isTournament: boolean;
	optionReserve: boolean;
	reserveLimit?: Number;
	startTime: string;
	url: string;
	attendance: EventAttendance[];
}

export interface EventAttendance {
	attended: boolean;
	id: string;
	name: string;
	notifications?: EventNotifications;
	reserved: boolean;
}

export interface EventNotifications {
	email?: string;
	id: string;
	name: string;
	phone?: string;
}
