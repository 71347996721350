import { ErrorHandler, Injectable } from '@angular/core';
import { Firebase_Functions_Service } from './functions.service';
import { firebase } from '../../environments/environment'; // Adjust the path as needed
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
	providedIn: 'root',
})
export class GlobalErrorHandler implements ErrorHandler {
	constructor(private firebaseFunctions: Firebase_Functions_Service) {}

	handleError(error: any): void {
		const errorMessage = error.message || 'An unknown error occurred';
		const errorDetail = error.customData || error;

		// Prepare a user-friendly error message object
		const msg = {
			summary: errorMessage,
			detail: errorDetail,
		};

		// Log to console or send email based on environment
		if (firebase.production) {
			this.firebaseFunctions.sendErrorEmail(msg);
		} else {
			console.error(msg.summary, msg.detail);
		}
	}
}
