import { NgModule } from '@angular/core';
import { AccordionModule } from 'primeng/accordion';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { ChartModule } from 'primeng/chart';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { EditorModule } from 'primeng/editor';
import { FileUploadModule } from 'primeng/fileupload';
import { InplaceModule } from 'primeng/inplace';
import { InputTextModule } from 'primeng/inputtext';
import { MenuModule } from 'primeng/menu';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { MultiSelectModule } from 'primeng/multiselect';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PanelModule } from 'primeng/panel';
import { ProgressBarModule } from 'primeng/progressbar';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SpeedDialModule } from 'primeng/speeddial';
import { SplitButtonModule } from 'primeng/splitbutton';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { ToastModule } from 'primeng/toast';
import { ToolbarModule } from 'primeng/toolbar';
import { TooltipModule } from 'primeng/tooltip';
import { TreeModule } from 'primeng/tree';
import { ChipsModule } from 'primeng/chips';

@NgModule({
	imports: [
		AccordionModule,
		AutoCompleteModule,
		ButtonModule,
		CalendarModule,
		CardModule,
		ChartModule,
		ConfirmDialogModule,
		DialogModule,
		DropdownModule,
		DynamicDialogModule,
		EditorModule,
		FileUploadModule,
		InplaceModule,
		InputTextModule,
		MenuModule,
		MessageModule,
		MessagesModule,
		MultiSelectModule,
		OverlayPanelModule,
		PanelModule,
		ProgressBarModule,
		ProgressSpinnerModule,
		ScrollPanelModule,
		SelectButtonModule,
		SpeedDialModule,
		SplitButtonModule,
		TabViewModule,
		TableModule,
		ToastModule,
		ToolbarModule,
		TooltipModule,
		TreeModule,
	],
	exports: [
		AccordionModule,
		AutoCompleteModule,
		ButtonModule,
		CalendarModule,
		CardModule,
		ChartModule,
		ChipsModule,
		ConfirmDialogModule,
		DialogModule,
		DropdownModule,
		DynamicDialogModule,
		EditorModule,
		FileUploadModule,
		InplaceModule,
		InputTextModule,
		MenuModule,
		MessageModule,
		MessagesModule,
		MultiSelectModule,
		OverlayPanelModule,
		PanelModule,
		ProgressBarModule,
		ProgressSpinnerModule,
		ScrollPanelModule,
		SelectButtonModule,
		SpeedDialModule,
		SplitButtonModule,
		TabViewModule,
		TableModule,
		ToastModule,
		ToolbarModule,
		TooltipModule,
		TreeModule,
	],
	providers: [MessageService, ConfirmationService],
})
export class NGPrimeModule {}
