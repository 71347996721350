import { FormArray, FormControl, FormGroup } from '@angular/forms';
type AnyObject = { [key: string]: any };
export class CustomFunctions {
	static calculateAge(birthday: string): number {
		/*
		VG6
		const epochDOB = Date.parse(birthday);
		const dob = new Date(epochDOB);
		const currentDate = new Date();

		if (dob < currentDate) {
			return Math.abs(dob.getUTCFullYear() - currentDate.getUTCFullYear());
		} else {
			return Math.abs(dob.getUTCFullYear() - currentDate.getUTCFullYear()) + 1;
		}
 		*/

		const birthDate = new Date(birthday);
		const today = new Date();
		let age = today.getFullYear() - birthDate.getFullYear();
		const m = today.getMonth() - birthDate.getMonth();

		// If the current month is before the birth month, or
		// if it's the birth month but the day is not yet reached,
		// subtract one year from the age
		if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
			age--;
		}

		return age;
	}

	static dateToDateString(d: Date) {
		if (typeof d === 'string') {
			try {
				d = new Date(d);
			} catch {
				throw new Error(`Cannot convert ${d} to a new date`);
			}
		}

		const yyyy = d.getFullYear().toString();
		const mm = (d.getMonth() + 1).toString();
		const dd = d.getDate().toString();

		const mmChars = mm.split('');
		const ddChars = dd.split('');

		return yyyy + '-' + (mmChars[1] ? mm : '0' + mmChars[0]) + '-' + (ddChars[1] ? dd : '0' + ddChars[0]);
	}

	static timeToDateString12(d: Date) {
		if (typeof d === 'string') {
			try {
				d = new Date(d);
			} catch {
				throw new Error(`Cannot convert ${d} to a new date`);
			}
		}

		const hh = d.getHours();
		let ml = 'AM';
		let h = hh;
		if (h >= 12) {
			h = hh - 12;
			ml = 'PM';
		}
		if (h == 0) {
			h = 12;
		}

		const givenHour = h.toString();
		const givenMinutes = d.getMinutes().toString();

		const minutesChars = givenMinutes.split('');

		return `${givenHour}:${minutesChars[1] ? givenMinutes : '0' + givenMinutes[0]} ${ml}`;
	}

	static timeToDateString24(d: Date) {
		if (typeof d === 'string') {
			try {
				d = new Date(d);
			} catch {
				throw new Error(`Cannot convert ${d} to a new date`);
			}
		}

		const givenHour = d.getHours().toString();
		const givenMinutes = d.getMinutes().toString();

		const minutesChars = givenMinutes.split('');
		const hourChars = givenHour.split('');

		return `${hourChars[1] ? givenHour : '0' + givenHour[0]}:${minutesChars[1] ? givenMinutes : '0' + givenMinutes[0]}`;
	}

	static dateToUTCString(d: Date) {
		const date = this.dateToDateString(d);
		const time = this.timeToDateString24(d);
		return `${date}T${time}`;
	}

	static dateToFriendlyDate(d: Date) {
		if (typeof d === 'string') {
			try {
				d = new Date(d);
			} catch {
				throw new Error(`Cannot convert ${d} to a new date`);
			}
		}

		const givenDate = new Date(d);

		const hh = givenDate.getHours();
		let ml = 'AM';
		let h = hh;
		if (h >= 12) {
			h = hh - 12;
			ml = 'PM';
		}
		if (h == 0) {
			h = 12;
		}

		const currentMonth = givenDate.toDateString().split(' ')[1];
		const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

		const dayName = days[givenDate.getDay()];
		const dd = givenDate.getDate().toString();
		const ddChars = dd.split('');
		const givenHour = h.toString();
		const givenMinutes = givenDate.getMinutes().toString();

		const minutesChars = givenMinutes.split('');

		return `${dayName} ${currentMonth} ${ddChars[1] ? dd : '0' + ddChars[0]} ${givenHour}:${minutesChars[1] ? givenMinutes : '0' + givenMinutes[0]} ${ml}`;
	}

	static formatDateString(dateString: string, mode: 'display' | 'store') {
		try {
			const dateStringTest = new RegExp('[0-9]{4}-[0-9]{2}-[0-9]{2}');
			if (dateStringTest.test(dateString)) {
				const dateStringYear = dateString.substring(0, 4);
				const dateStringMonth = dateString.substring(5, 7);
				const dateStringDay = dateString.substring(8, 10);
				if (mode === 'store') {
					return `${dateStringYear}-${dateStringMonth}-${dateStringDay}`;
				} else if (mode === 'display') {
					return `${dateStringMonth}/${dateStringDay}/${dateStringYear}`;
				} else {
					throw new Error(`Mode ${mode} does not match 'display' or 'store'`);
				}
			} else {
				throw new Error(`Invalid Date ${dateString}. Required format YYYY-MM-DD`);
			}
		} catch (error) {
			console.log('formatDateString', error);
			return;
		}
	}

	static localClientTime(): string {
		const tzoffset = new Date().getTimezoneOffset() * 60000;
		return new Date(Date.now() - tzoffset).toISOString().slice(0, 19);
	}

	static midnight(daysOffset = 0): string {
		const tzoffset = new Date().getTimezoneOffset() * 60000;
		const msOffset = daysOffset * 24 * 60 * 60000;

		const d = new Date(Date.now() - tzoffset + msOffset).toISOString().slice(0, -1);

		const date = new Date(d);

		const dayAsInt = date.setDate(date.getDate());
		const givenDate = new Date(dayAsInt);
		const day = `0${givenDate.getDate()}`;
		const month = `0${givenDate.getMonth() + 1}`;
		const year = `${givenDate.getFullYear()}`;

		const formattedTime = `${year}-${month.substr(-2)}-${day.substr(-2)}T00:00:00`;

		return formattedTime;
	}

	static shuffle(array: any[]): any[] {
		let currentIndex: number = array.length,
			temporaryValue: any,
			randomIndex: number;

		// While there remain elements to shuffle...
		while (0 !== currentIndex) {
			// Pick a remaining element...
			randomIndex = Math.floor(Math.random() * currentIndex);
			currentIndex -= 1;

			// And swap it with the current element.
			temporaryValue = array[currentIndex];
			array[currentIndex] = array[randomIndex];
			array[randomIndex] = <never>temporaryValue;
		}

		return array;
	}

	static stringy(object: Record<string, unknown>) {
		let cache: unknown[] = [];

		const data: string = JSON.stringify(object, (key: string, value: unknown) => {
			if (typeof value === 'object' && value !== null) {
				if (cache.indexOf(value as never) !== -1) {
					// Duplicate reference found, discard key
					return;
				}
				// Store value in our collection
				cache.push(value);
			}
			return value;
		});
		cache = [];
		return data;
	}

	static stringyPretty(object: any) {
		let cache: unknown[] = [];

		const data: string = JSON.stringify(object, (key: string, value: unknown) => {
			if (typeof value === 'object' && value !== null) {
				if (cache.indexOf(value as never) !== -1) {
					// Duplicate reference found, discard key
					return;
				}
				// Store value in our collection
				cache.push(value);
			}

			return value;
		});
		cache = [];

		const cleanData = JSON.parse(data);
		const prettyData = JSON.stringify(cleanData, null, '\t');

		return prettyData;
	}

	static findInvalidControlsRecursive(formToInvestigate: FormGroup | FormArray): string[] {
		var invalidControls: string[] = [];
		let recursiveFunc = (form: FormGroup | FormArray) => {
			Object.keys(form.controls).forEach(field => {
				const control = form.get(field);
				if (control?.status === 'INVALID') {
					if (control.errors && control.errors['alert']) {
						invalidControls.push(control.errors['alert']);
					} else {
						if (control instanceof FormControl) invalidControls.push(`invalid ${field}`);
					}
				}
				if (control instanceof FormGroup) {
					recursiveFunc(control);
				} else if (control instanceof FormArray) {
					recursiveFunc(control);
				}
			});
		};

		recursiveFunc(formToInvestigate);
		return invalidControls;
	}

	// ! DEPRECATED: Use utilities/extractUniqueValues instead
	static extractUniqueValues(obj: AnyObject, keysToFind: string[]): any[] {
		const resultSet = new Set<any>();
		const visitedObjects = new WeakSet<AnyObject>();

		function recurse(currentObj: AnyObject) {
			if (visitedObjects.has(currentObj)) {
				return;
			}
			visitedObjects.add(currentObj);

			for (const key in currentObj) {
				// Using hasOwnProperty from the Object prototype
				if (Object.prototype.hasOwnProperty.call(currentObj, key)) {
					if (keysToFind.includes(key)) {
						resultSet.add(currentObj[key]);
					}
					if (typeof currentObj[key] === 'object' && currentObj[key] !== null) {
						recurse(currentObj[key]);
					}
				}
			}
		}

		recurse(obj);
		return Array.from(resultSet);
	}
}

export interface Email {
	sender: string;
	to: string | string[];
	cc?: string | string[];
	bcc?: string | string[];
	subject: string;
	text?: string;
	html?: string;
}

export function clone(arr: any[]) {
	return JSON.parse(JSON.stringify(arr));
}
export class CustomError extends Error {
	customData?: any;

	constructor(message: string, customData?: any) {
		super(message);
		this.customData = customData;
		Object.setPrototypeOf(this, CustomError.prototype);
	}
}

export function throwCustomError(error: any, data?: Record<string, unknown>[]) {
	if (!(error instanceof Error)) {
		// Handle non-Error objects being thrown
		console.error('Caught an unexpected type of throw:', error);
		throw new CustomError('Unknown error type', {
			originalError: error,
			...(data ? { additionalData: data } : {}),
		});
	} else if (!(error instanceof CustomError)) {
		// Convert non-CustomError errors to CustomError
		const stackArray = error.stack ? error.stack.split('\n') : [];
		const customError = new CustomError(error.message, {
			originalError: { ...error, stack: stackArray },
			...(data ? { additionalData: data } : {}),
		});
		throw customError;
	} else {
		// Modify existing CustomError if additional data is provided
		if (data) {
			error.customData = { ...error.customData, additionalData: data };
		}
		throw error;
	}
}
