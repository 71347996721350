export interface Competitor {
	age: number;
	ataNumber: string;
	email: string;
	familyName: string;
	gender: 'male' | 'female';
	givenName: string;
	height: string;
	rank: string;
	recreational: string;
	school: string;
	special: string;
	tiny: string;
	paypal?: PaypalTransaction;

	schoolInfo?: SchoolInfo;
}

export interface PaypalTransaction {
	date: string;
	name: string;
	email: string;
	item: string;
	street: string;
	city: string;
	state: boolean;
	zip: string;
	phone: boolean;
}

export interface SchoolInfo {
	name: string;
	street: string;
	city: string;
	state: string;
	zip: string;
}

export interface StudentParticipation {
	givenName: string;
	familyName: string;
	ataNumber: string;
	tournament: string;
	date: string;
}

export interface TournamentStanding {
	ageGroup: '1-8' | '9-10' | '11-12' | '13-14' | '15-17' | '18-29' | '30-39' | '40-49' | '50-59' | '60-69' | '70+';
	archive: boolean;
	ataName: string;
	ataNumber: string;
	currentStanding: number;
	event: 'Combat Weapons' | 'Creative Forms' | 'Creative Weapons' | 'Forms' | 'Sparring' | 'Weapons Forms' | 'X-treme Forms' | 'X-treme Weapons';
	points: number;
	rankGroup: '1DB' | '1DB/2DB/3DB' | '2DB/3DB' | '4DB/5DB' | 'COLOR' | 'SPECIAL';
	season: '2023-2024' | '2024-2025';
}

export interface ViewStandings {
	name: string;
	data: TournamentStanding[];
}

export interface EZSignup {
	'Last Name': string;
	'First Name': string;
	'ATA#': string;
	Age: string;
	'Current Rank': string;
	School: string;
	Gender: string;
	Division: string;
	Rank: string;
	'Rank Group': string;
	Height: string;
	'Trad Forms': string;
	'Trad Spar': string;
	'Trad Wpns': string;
	'Comb Wpns': string;
	'Creat Forms': string;
	'Creat Wpns': string;
	'ATA-X Forms': string;
	'ATA-X Wpns': string;
	Tiny: string;
	Rec: string;
	Spec: string;
}
