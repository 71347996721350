export interface ChatSubject {
	actualStartTime?: string;
	category: 'Staging' | 'Judges' | 'Announcements';
	description: string;
	estimatedStartTime: string;
	id?: string;
	sponsor?: string;
	sponsorURL: string;
	sponsorMessage: string;
	sponsorPhone: string;
	subjectStatus: 'Open' | 'Closed' | 'Working' | 'Finished';
}

export const OPTION_STATUS: IStatus[] = [
	{ label: 'Select Status', value: '' },
	{ label: 'Closed', value: 'Closed' },
	{ label: 'Open', value: 'Open' },
	{ label: 'Working', value: 'Working' },
	{ label: 'Finished', value: 'Finished' },
];

export interface IStatus {
	label: string;
	value: string;
}

export interface ICategory {
	label: string;
	value: string;
}
